/*
 * Listing Container
 */

import React from 'react';
import util from '../../../utilities';
import Container from '../base/container.jsx';
import Listing from './listing.jsx';
import Pagination from '../shared/pagination.jsx';

/**
 * Class representing the Listing Container
 * @extends React.component
 */

class ListingContainer extends Container {

    constructor (props) {
        super(props);

        this.handleLoad = this.handleLoad.bind(this);
        this.toggleFilters = this.toggleFilters.bind(this);
        this.state.totalPages = this.props.totalPages || 1;
        this.state.total = this.props.total || 0;
        this.state.filtersActive = !!this.props.filters.type.current || false;
        this.state.featuredItems = util.try(() => this.props.content.acf.featured);
    }

    /**
     * Handle load
     */
    handleLoad(data) {
        this.setState({
            totalPages: data.totalPages,
            total: data.total
        });
    }

    handleSelect(value) {
        // this.toggle(false);
        if (typeof this.handleFilter === 'function') {
            if(value === 'all') {
                value = '';
            }
            this.handleFilter('type', value);
        }
    }

    toggleFilters() {
        this.setState({
            filtersActive: !this.state.filtersActive
        });
    }

    render () {

        const items = this.props.items;
        const pageTitle = util.try(() => this.props.page.name) ? 'All tagged with “' + this.props.page.name + '”' : 'Tags';

        let noContentMessage = 'There is currently nothing tagged with “' + this.props.page.name + '”';
        if (this.props.filters.type.current) {
            const option = this.props.filters.type.options.filter((option) => {
                if (option.value === this.props.filters.type.current) {
                    return option.label;
                }
            })[0];
            noContentMessage = 'There is currently nothing tagged with “' + this.props.page.name + '” in ' + util.try(() => option.label);
        }

        const activeClass = this.state.filtersActive ? 'show' : 'hide';

        let sortBy;

        let filterOptions = util.try(() => this.props.filters.type.options.map((option,i) => {

            const activeClass = (this.props.filters.type.current === option.value) ? 'bg-accent-green' : 'bg-white';

            return (
                <li
                    key={i}
                    onClick={this.handleFilter.bind(this, 'type', option.value)}
                    className={`bg-hover-accent-green caps left mr1 h7 mb1 ${activeClass}`}>
                    <button className='button p2 caps line-height-1' dangerouslySetInnerHTML={{__html: option.label}} />
                </li>
            );

        }));

        //
        // Combine our filterOptions
        filterOptions = (
            <ul className='list-reset left'>
                <li
                    className='left mr2 caps h8 py2 line-height-1'>Filter By:</li>
                <li
                    key={-1}
                    tabIndex={0}
                    onClick={this.handleFilter.bind(this, 'type', '')}
                    className={'bg-hover-accent-green left mr1 h7 bg-white mb1 ' + (util.try(() => this.props.filters.type.current) || 'bg-accent-green')}>
                    <button className='button p2 line-height-1 caps'>All</button>
                </li>
                {filterOptions}
            </ul>
        );

        const icon = this.state.filtersActive
            ? 'icon-arrow-up'
            : 'icon-arrow-down';

        return (
            <div>

                <div>
                    <div className='clearfix relative'>
                        <div className='container clearfix'>
                            <div className='col col-9'>
                                <h1 className='caslon h2 px5 md-pr9 m0 sm-pt6 sm-pb5 pt5 pb4'>
                                    {pageTitle}
                                </h1>
                            </div>
                        </div>
                        <div className='border-top border-light-grey-001'>
                            <div className='container clearfix relative'>
                                <div className='col col-9 md-col-6'>
                                    <h5
                                        className='caslon px5 md-pr9 m0 sm-pt6 sm-pb6 pt5 pb5 sm-h4'
                                        dangerouslySetInnerHTML={{__html: util.try(() => this.props.page.description)}} />
                                </div>
                                <div className={`absolute right-0 height-100 col-3 ${util.try(() => this.state.filtersActive) ? 'bg-pastel-blue' : ''}`}>

                                    <button
                                        className='button height-100 col-12 left-align'
                                        onClick={this.toggleFilters}>

                                        {/* Mobile filter text */}
                                        <div className={util.try(() => this.props.tagsListing) ? 'absolute bottom-0 pb6' : 'inline'}>
                                            <span className='md-hide border-bottom border-accent-green ml5'>
                                                Filter
                                            </span>
                                        </div>
                                        {/* Desktop filter text */}
                                        <span className='md-show'>
                                            <span className='absolute v-align'>
                                                <i className={`h2 absolute ${icon}`}/>
                                            </span>
                                            <span className='pl5 md-pl9 block md-col-7'>Filter by Category</span>
                                        </span>

                                    </button>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`bg-pastel-blue clearfix col-12 py6 ${activeClass}`}>
                        <div className='container px5 md-pr9'>
                            {filterOptions}
                        </div>
                    </div>
                </div>
                {/* Listing */}
                <Listing
                    path='/tags-json'
                    query={{
                        page: this.state.page,
                        type: util.try(() => this.state.filters.type.current),
                        'tag_slug': util.try(() => this.props.page.slug)
                    }}
                    items={items}
                    pageSize={3}
                    onLoad={this.handleLoad}
                    page={this.state.page}
                    total={this.state.total}
                    noContentMessage={noContentMessage} />
                <div className={(this.state.total > 0) ? 'show' : 'hide'}>
                    <Pagination
                        current={this.state.page}
                        total={this.state.totalPages}
                        navigate={this.handlePage} />
                </div>

            </div>

        );
    }
}

module.exports = ListingContainer;
