/*
 *  Article / Current. News, blog, press etc.
 */

import React from 'react';
import AjaxListing from '../base/ajax-listing.jsx';
import util from '../../../utilities';

// Grid listing items
import FeatureItem from '../items/feature-business-item.jsx';
import Item from '../items/item.jsx';

import ListingGrid from '../base/listing-grid.jsx';

/**
 * Class representing the Article
 * @extends AjaxListing
 */
class TagsListing extends AjaxListing {

    render() {
        const blockSize = 7;
        let items;
        let arItems = this.state.items;

        // if(this.props.query.page === 1 && (!this.props.query.subject || !this.props.query.type)) {
        //     //
        //     // combine our featured if on listing landing page,
        //     // with no subject filter
        //     arItems = this.props.featuredItems.concat(arItems);
        // }

        const formatTypeTitle = (type) => {
            switch (type) {
            case 'food_drink':
                type = 'Food & Drink';
                break;
            case 'shops_stalls':
                type = 'Shops & Stalls';
                break;
            case 'event':
                type = 'Events';
                break;
            case 'article':
                type = 'Journal';
                break;
            default:
                type = null;
            }
            return type;
        } ;

        if (util.try(() => arItems)) {
            items = arItems.map((item, i) => {


                //
                // Ever 7th son, feature.
                if(i%blockSize===0) {
                    return (<FeatureItem
                        typeTitle={formatTypeTitle(item.type)}
                        key={i}
                        item={item}
                        />);
                } else {
                    return (<Item
                        typeTitle={formatTypeTitle(item.type)}
                        key={i}
                        item={item}
                        />);
                }
            });
        }
        return (
            <div className="mln1px clearfix">
                <ListingGrid blockSize={blockSize}>
                    {items}
                </ListingGrid>
                {this.props.total <= 0 && !this.state.loading &&
                    <div className='container'>
                        <h4 className='caslon px5 md-px9 m0 pt7 pb5'>
                            {this.props.noContentMessage}
                        </h4>
                    </div>
                }
            </div>
        );
    }
}

module.exports = TagsListing;
