/*
 * Listing Container
 */

import React from 'react';
import util from '../../../utilities';
import Container from '../base/container.jsx';
import Listing from './listing.jsx';
import Filters from '../../ui/filter.jsx';
import Pagination from '../shared/pagination.jsx';

/**
 * Class representing the Listing Container
 * @extends React.component
 */

class ListingContainer extends Container {

    constructor (props) {
        super(props);

        this.handleLoad = this.handleLoad.bind(this);
        this.toggleFilters = this.toggleFilters.bind(this);
        this.state.totalPages = this.props.totalPages || 1;
        this.state.filtersActive = false;
        this.state.featuredItems = util.try(() => this.props.content.acf.featured);
    }

    /**
     * Handle load
     */
    handleLoad(data) {
        this.setState({
            totalPages: data.totalPages
        });
    }

    handleUpdate(key, value) {
        if (typeof this.handleFilter === 'function') {
            if(value === 'all') {
                value = '';
            }
            this.handleFilter(key, value);
        }
    }

    toggleFilters() {
        this.setState({
            filtersActive: this.state.filtersActive ? false : true
        });
    }

    render () {

        const items = this.props.items;

        return (
            <div>
                <Filters
                    onUpdate={this.handleUpdate.bind(this, 'subject')}
                    filters={util.try(() => this.props.filters.subject)}
                    filterText='Filter by Subject'>

                    <div className='col col-12 sm-col-9'>
                        <h1 className='caslon h2 px5 md-pr9 m0 pt7 pb5'>
                            {this.props.title}
                        </h1>
                    </div>

                </Filters>
                {/* Listing */}
                <Listing
                    path='/journal-json'
                    query={{
                        page: this.state.page,
                        subject: util.try(() => this.state.filters.subject.current)
                    }}
                    items={items}
                    featuredItems={this.state.featuredItems}
                    pageSize={3}
                    onLoad={this.handleLoad}
                    page={this.state.page} />

                <Pagination
                    current={this.state.page}
                    total={this.state.totalPages}
                    navigate={this.handlePage} />
            </div>

        );
    }
}

module.exports = ListingContainer;
