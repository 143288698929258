/*
 * Read more
 */

import React from 'react';


/**
 * Class representing a read more section
 *
 * Show / hide extended text
 * Optionally provide children that are visible when the disclosure is open -
 * This allows for more complex content, but text is still required for the closed state
 *
 * @extends React.Component
 * @param {string} text
 * @param {number} threshold
 * @param {jsx} children
 */
class ReadMore extends React.Component {

    constructor() {
        super();
        this.toggle = this.toggle.bind(this);
        this.state = {
            open: false
        };
    }

    toggle() {
        this.setState({
            open: !this.state.open
        });
    }

    render() {

        let readMore;
        if (!this.props.content) {
            return (<span />);
        }

        if(this.props.hidden) {
            readMore = (
                <div className='pt4'>
                    <button
                        onClick={this.toggle}
                        className='button m0 p0 caslon border-bottom border-accent-green'>
                        {this.state.open ? 'Read Less' : 'Read More'}
                    </button>
                </div>
            );
        }

        return (
            <div>
                <div className="rte">
                    <div dangerouslySetInnerHTML={{__html: this.props.content}} />
                    {this.state.open &&
                        <div dangerouslySetInnerHTML={{__html: this.props.hidden}} />
                    }
                </div>
                {readMore}
            </div>
        );
    }
}

module.exports = ReadMore;
