import React from 'react'

export default class FreeText extends React.Component {
    render() {
        return (
            <div className='bg-pastel-blue rte'>
                <div className='clearfix container h4 md-h9 lg-h4 blue caslon'>
                    <div className='col col-12 sm-col-6 py7 md-py9'>
                        <div
                            className='px5 lg-px9'
                            dangerouslySetInnerHTML={{__html: this.props.column1}} />
                    </div>
                    <div className='col col-12 sm-col-6 py7 md-py9 border-top sm-border-0 border-light-grey-001'>
                        <div
                            className='px5 lg-px9'
                            dangerouslySetInnerHTML={{__html: this.props.column2}} />
                    </div>
                </div>
            </div>
        );
    }
}
