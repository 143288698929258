/**
*   Event Listing item
*/
import React from 'react';
import util from '../../../utilities';
import Image from '../../images/image.jsx';

class EventListingItem extends React.Component {

    render() {
        const event = this.props.event;
        const url = '/events/' + event.post_name;

        return (
            <div className='border-bottom border-light-grey-001'>
                <a href={url} className='block clearfix bg-hover-pastel-blue p2 container'>
                    <div className='relative clearfix'>
                        <div className='md-col-6 md-absolute height-100 md-pr2'>
                            <div className='height-100 relative aspect-12-5 md-aspect-none overflow-hidden'>
                                <Image
                                    className='object-fit absolute'
                                    mdWidth='80'
                                    aspect='4-2'
                                    image={util.try(() => event.acf.listing_image)} />
                            </div>
                        </div>
                        <div className={(event.acf.featured ? 'md-absolute bottom-0 right-0': '') + ' col-right col-12 md-col-6 z1'}>
                            <div className={(event.acf.featured ? 'sm-mln2 sm-pl2': '') + ' bg-white clearfix pb6'}>
                                <div className='col col-12 sm-col-6'>
                                    <div className='px2 sm-pr5 md-pl5'>
                                        <span className='caslon h4 red mt6 block'
                                            dangerouslySetInnerHTML={{__html:util.try(() => event.acf.time_info)}} />
                                    </div>
                                </div>
                                <div className='col col-12 sm-col-6 relative sm-mtn7 md-mt0 bg-white'>
                                    <div className='px2 sm-px5 md-pl5'>
                                        <div className='sm-hide'>—</div>
                                        <h4 className='caslon mb0 mt0 sm-mt6 mb5'>{util.try(() => event.post_title)}</h4>
                                        <p className='mt0 caslon'>{util.try(() => event.acf.standfirst)}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </a>
            </div>
        );
    }
}
module.exports = EventListingItem;
