/*
 * Newsletter page component
 */

import React from 'react';
import util from '../utilities';
import Header from '../partials/global/header.jsx';
import Footer from '../partials/global/footer.jsx';
import Page from '../partials/layout/page.jsx';


class Newsletter extends React.Component {

    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.state = {
            message: false,
            firstname: '',
            lastname: '',
            email: '',
            loading: false,
            subscribed: false
        };
    }

    handleChange(type, e) {
        const state = {[type]: e.target.value};
        this.setState(state);
    }

    /**
     * Handle submit
     * @param {object} e
     */
    handleSubmit(e) {
        e.preventDefault();
        if (!this.state.email.length <= 0) {

            if (!this.state.subscribed) {
                this.setState({
                    loading: true
                }, this.postForm);
            }

        } else {
            this.updateMessage('empty');
        }
    }

    /**
     * Post form
     */
    postForm() {
        const post = fetch('/subscribe', {
            method: 'POST',
            body: new FormData(this.refs.form)
        });

        post.then((res) => res.json())
            .then((json) => this.updateMessage(json.message))
            .catch((err) => this.updateMessage('error'));
    }

    /**
     * Update message
     * @param {object} response
     */
    updateMessage(response) {
        const message = {
            'empty': 'You must provide a valid email address in order to subscribe.',
            'success': 'Thank you for subscribing! You will be receiving a confirmation email shortly.',
            'duplicate': 'You are already subscribed to our mailing list with this email address.',
            'error': 'There was a problem subscribing you to the newsletter, please try again later.'
        };

        let state = {
            message: message[response],
            loading: false
        };

        if(response === 'success') {
            state.subscribed = true;
            state.email = '';
        }

        this.setState(state);
    }

    render() {

        let icon = '';
        let nudge = '';
        let bgColor = '';
        let rotate = '';
        let disabled = false;

        if (this.state.loading) {
            icon = 'icon-spinner';
            bgColor = 'bg-grey-002';
            rotate = 'rotate';
        } else if (this.state.subscribed) {
            icon = 'icon-tick large-icon white';
            bgColor = 'bg-grey-004';
            disabled = true;
        } else {
            icon = 'icon-chevron-right';
            nudge = 'nudge-right';
            bgColor = 'bg-white';
        }

        const message = this.state.message && (
            <div className='col-12 px5 pt6 center h7 caps'>
                {this.state.message}
            </div>
        );

        return (
            <Page>
                <Header settings={util.try(() => this.props.settings)} />

                <div className='bg-pastel-blue'>
                    <div className='container pt5 pb7 px5 md-px0'>
                        <div className='col-12 md-col-6 mx-auto caslon blue center'>
                            <h1>{this.props.page.acf.title}</h1>
                            <h3>{this.props.page.acf.subtitle}</h3>
                        </div>
                        <form
                            ref='form'
                            className='col-12 md-col-6 mx-auto'
                            onSubmit={this.handleSubmit}>
                            <div className='mx-auto col-12 md-col-6'>
                                <input
                                    placeholder='first name'
                                    name='fname'
                                    className='border-0 p2 col-12 h7 caps center line-height-1 not-rounded'
                                    onChange={this.handleChange.bind(null, 'firstname')}
                                    value={this.state.firstname}
                                    type='text' />
                            </div>
                            <div className='mx-auto col-12 md-col-6 pt2'>
                                <input
                                    placeholder='last name'
                                    name='lname'
                                    className='border-0 p2 col-12 h7 caps center line-height-1 not-rounded'
                                    onChange={this.handleChange.bind(null, 'lastname')}
                                    value={this.state.lastname}
                                    type='text' />
                            </div>
                            <div className='mx-auto col-12 md-col-6 pt2'>
                                <input
                                    placeholder='email'
                                    ref='email'
                                    name='email'
                                    className='border-0 p2 col-12 h7 caps center line-height-1 not-rounded'
                                    onChange={this.handleChange.bind(this, 'email')}
                                    value={this.state.email}
                                    type='email' />
                            </div>
                            <div className='col-6 mx-auto pt2'>
                                <button
                                    type='submit'
                                    disabled={disabled}
                                    className='button p0 col-12'>
                                    <div className='col-12 caps h7 line-height-1 bg-accent-green cursor-pointer p2 black'>
                                        subscribe
                                    </div>
                                </button>
                            </div>
                            {message}
                        </form>
                        {this.props.page.acf.privacy_policy && (
                            <div className='col-12 md-col-6 sm-mx-auto'>
                                <div
                                    className='center sm-left-align pt7 pl5'
                                    dangerouslySetInnerHTML={{__html: this.props.page.acf.privacy_policy}} />
                            </div>
                        )}
                    </div>
                </div>

                <Footer settings={this.props.settings} />
            </Page>
        );
    }
};

module.exports = Newsletter;
