/*
 * Journal
 */

import React from 'react';
import moment from 'moment';
import util from '../../utilities';
import Page from '../../partials/layout/page.jsx';
import Header from '../../partials/global/header.jsx';
import Footer from '../../partials/global/footer.jsx';
import Image from '../../partials/images/image.jsx';
import ReadMore from '../../partials/layout/read-more.jsx';
import TagCloud from '../../partials/layout/tag-cloud.jsx';
import Standfirst from '../../partials/layout/standfirst.jsx';
import Description from '../../partials/shared/description.jsx';
import FreeText from '../../partials/shared/free-text.jsx';
import Gallery from '../../partials/shared/gallery.jsx';
import RelatedContent from '../../partials/layout/related-content.jsx';



class Event extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        const dates = this.props.page.acf.dates.split(',');

        // reduces an array of dates down to min and max and converts result to string
        const dateString = dates.reduce((res, item, i, self) => {
            const date = moment(item, 'DD-MM-YYYY').valueOf();
            const format = date => moment(date).format('ddd D MMM');

            if (!res.start || date < res.start) {
                res.start = date;
            }
            if (!res.end || date > res.end) {
                res.end = date;
            }

            if (self.length <= 1) {
                return format(date);
            } else if (i === self.length - 1) {
                return `${format(res.start)} – ${format(res.end)}`;
            } else {
                return res;
            }
        }, {});

        const dateRange = (
            <div className='ag-book mtn4'>
                {dateString}
            </div>
        );

        let dateStandfirst;
        if (util.try(() => this.props.page.acf.date_overview)) {
            dateStandfirst = (
                <div className='ag-book mtn4'>
                    {this.props.page.acf.date_overview}
                </div>
            );
        } else {
            dateStandfirst = dateRange;
        }

        return (
            <Page>
                <Header settings={util.try(() => this.props.settings)} />

                <Standfirst
                    type={'Events'}
                    typeLink='/events'
                    paths={this.props.settings.urls}
                    title={this.props.page.title.rendered}
                    standfirst={dateStandfirst} />

                <Description
                    image={this.props.page.acf.image}
                    content={this.props.page.acf.content}
                    hidden={this.props.page.acf.hidden_content} />

                {(util.try(() => this.props.page.acf.column_1) ||
                    util.try(() => this.props.page.acf.column_2)) &&
                    <FreeText
                        column1={util.try(() => this.props.page.acf.column_1)}
                        column2={util.try(() => this.props.page.acf.column_2)} />
                }

                {util.try(() => this.props.page.acf.gallery) &&
                    <Gallery
                        images={this.props.page.acf.gallery} />
                }

                {util.isArray(this.props.page.tag) &&
                    <div className='border-top border-light-grey-001' />
                }

                {util.isArray(this.props.page.tag) &&

                    <TagCloud
                        bgColor={this.props.page.acf.gallery || 'border-top border-light-grey-001 bg-pastel-blue'}
                        tags={this.props.page.tag} />
                }

                {util.try(() => this.props.page.acf.related_content) &&
                    <RelatedContent
                        title='Related Content'
                        content={util.try(() => this.props.page.acf.related_content)} />
                }

                <Footer settings={this.props.settings} />
            </Page>
        );
    }
}

module.exports = Event;
