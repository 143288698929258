import React from 'react';
import util from '../../utilities';

class ShareButton extends React.Component {

    constructor() {
        super();
        this.toggle = this.toggle.bind(this);
        this.state = {
            open: false
        };
    }

    toggle() {
        this.setState({open: !this.state.open});
    }

    render() {
        if (!util.try(() => this.props.url) || !util.try(() => this.props.title)) {
            return null;
        }

        const url = encodeURIComponent(this.props.url);
        const title = encodeURIComponent(this.props.title);

        const shareButtons = [
            {icon: 'icon-share-facebook', link: `https://www.facebook.com/sharer/sharer.php?u=${url}`},
            {icon: 'icon-share-twitter', link: `https://twitter.com/home?status=${url}`},
            {icon: 'icon-share-email', link: `mailto:?subject=${title}&body=${url}`}
        ].map((button, i) => (
            <a
                key={i}
                target='_blank'
                onClick={this.toggle}
                href={button.link}
                className='bg-white py2 block col-12 cursor-pointer line-height-6 border border-accent-green mbn1px'>
                <div
                    className='center'>
                    <i className={`${button.icon} h6 blue letter-spacing-0 line-height-6`} />
                </div>
            </a>
        ));

        const toggle = this.state.open
            ? ''
            : 'hide';

        const bgColor = this.state.open
            ? 'bg-accent-green'
            : 'bg-white';

        const icon = this.state.open
            ? 'icon-close'
            : 'icon-share';

        return (
            <div className='relative z1'>
                <div
                    onClick={this.toggle}
                    className={`${bgColor} relative p2 cursor-pointer line-height-6`}>
                    <div className='pt1px table mr1px ml1px'>
                        <i className={`${icon} blue h6 letter-spacing-0 line-height-6 table-cell`} />
                    </div>
                </div>
                <div className={`absolute left-0 right-0 ${toggle}`}>
                    {shareButtons}
                </div>
            </div>
        );
    }
}

module.exports = ShareButton;
