/*
 *  Events listing
 */

import React from 'react';
import AjaxListing from '../base/ajax-listing.jsx';
import util from '../../../utilities';
import Item from './event-listing-item.jsx';
import ItemFeatured from './event-listing-item-featured.jsx';
import moment from 'moment';

/**
 * Class representing the Article
 * @extends AjaxListing
 */
class EventsListing extends AjaxListing {

    render() {

        const items = util.try(() => this.state.items);
        let lastDate;
        let dateBanner;
        // Loop our events
        const events = items.map((event, i) => {

            dateBanner = null;
            if(lastDate !== event.event_date) {
                //
                // Date banner insert
                dateBanner = (
                    <div
                        key={i-1}
                        className="clearfix  border-bottom border-light-grey-001">
                        <div className="container">
                            <h4 className='px5 m0 py5'>
                                {moment(event.event_date).format('ddd D MMM')}
                            </h4>
                        </div>
                    </div>
                );
            }
            lastDate = event.event_date;

            // Featured event
            if(event.acf.featured) {
                return (
                    <span key={i}>
                        {dateBanner}
                        <ItemFeatured key={i} event={event} />
                    </span>
                );
            }

            // Event item
            return (
                <span key={i}>
                    {dateBanner}
                    <Item key={i} event={event} />
                </span>
            );
        });

        return (
            <div className="mln1px clearfix border-top border-light-grey-001">
                {events}
                {items.length <= 0 && !this.state.loading &&
                    <div className='container'>
                        <h4 className='caslon px5 md-pr9 m0 pt7 pb5'>
                            There are no events within that range
                        </h4>
                    </div>
                }
            </div>
        );
    }
}

module.exports = EventsListing;
