import React from 'react';

class Newsletter extends React.Component {

    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.state = {
            message: false,
            name: '',
            email: '',
            loading: false,
            subscribed: false
        };
    }

    handleChange(type, e) {
        const state = {[type]: e.target.value};
        this.setState(state);
    }

    /**
     * Handle submit
     * @param {object} e
     */
    handleSubmit(e) {
        e.preventDefault();
        if (!this.state.email.length <= 0) {

            if (!this.state.subscribed) {
                this.setState({
                    loading: true
                }, this.postForm);
            }

        } else {
            this.updateMessage('empty');
        }
    }

    /**
     * Post form
     */
    postForm() {
        const post = fetch('/subscribe', {
            method: 'POST',
            body: new FormData(this.refs.form)
        });

        post.then((res) => res.json())
            .then((json) => this.updateMessage(json.message))
            .catch((err) => this.updateMessage('error'));
    }

    /**
     * Update message
     * @param {object} response
     */
    updateMessage(response) {
        const message = {
            'empty': 'You must provide a valid email address in order to subscribe.',
            'success': 'Thank you for subscribing! You will be receiving a confirmation email shortly.',
            'duplicate': 'You are already subscribed to our mailing list with this email address.',
            'error': 'There was a problem subscribing you to the newsletter, please try again later.'
        };

        let state = {
            message: message[response],
            loading: false
        };

        if(response === 'success') {
            state.subscribed = true;
            state.email = '';
        }

        this.setState(state);
    }

    render() {
        let icon = '';
        let nudge = '';
        let bgColor = '';
        let rotate = '';
        let disabled = false;

        if (this.state.loading) {
            icon = 'icon-spinner';
            bgColor = 'bg-grey-002';
            rotate = 'rotate';
        } else if (this.state.subscribed) {
            icon = 'icon-tick large-icon white';
            bgColor = 'bg-grey-004';
            disabled = true;
        } else {
            icon = 'icon-chevron-right';
            nudge = 'nudge-right';
            bgColor = 'bg-white';
        }

        const message = this.state.message && (
            <div className='p0 pt1 md-px5 lg-pl9 limit-line-width h7 caps'>
                {this.state.message}
            </div>
        );

        return (
            <div className='clearfix px5 md-px0'>
                <div className='clearfix mt3 mb7 md-mt9 md-mb9 white relative'>
                    <div className='overflow-hidden'>
                        <div className='clearfix white relative'>
                            <div className='mb0 mt2 sm-mt3 md-mr5 md-ml5 md-mtn2px lg-absolute left-0 top-0'>
                                <i className='icon-arrow-right' />
                            </div>
                            <div className='overflow-hidden'>
                                <h4
                                    className='mt1 md-mb6 md-pl5 md-pl5 lg-pl9 line-height-1 md-h3'
                                    dangerouslySetInnerHTML={{__html: this.props.call_to_action}} />
                            </div>
                        </div>
                        <form ref='form' onSubmit={this.handleSubmit}>
                            <div className='md-ml5 lg-ml9 col-12 md-col-6'>
                                <input
                                    placeholder='Your First Name'
                                    name='fname'
                                    className='border-0 p2 col-12 h7 caps line-height-1 not-rounded'
                                    onChange={this.handleChange.bind(null, 'name')}
                                    value={this.state.name}
                                    type='text' />
                            </div>
                            <div className='md-ml5 lg-ml9 col-12 md-col-6 pt1'>
                                <input
                                    placeholder='Your Email'
                                    ref='email'
                                    name='email'
                                    className='border-0 p2 col-12 h7 caps line-height-1 not-rounded'
                                    onChange={this.handleChange.bind(this, 'email')}
                                    value={this.state.email}
                                    type='email' />
                            </div>
                            <button
                                type='submit'
                                disabled={disabled}
                                className='button p0 pt1 md-pl5 lg-pl9'>
                                <div className='col caps h7 line-height-5 bg-accent-green cursor-pointer py1 px2 black'>
                                    subscribe
                                </div>
                            </button>
                            {message}
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

Newsletter.defaultProps = {
    call_to_action: 'Don\’t be a stranger'
};

module.exports = Newsletter;
