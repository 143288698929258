import React from 'react';
import util from '../../utilities';
import ShareButton from '../ui/share-button.jsx';

class Standfirst extends React.Component {
    render() {
        let standfirst;
        if (util.try(() => this.props.standfirst)) {
            if (typeof this.props.standfirst === 'object') {
                standfirst = (
                    <div className='caslon h5 sm-col-9 md-col-7 mx-auto'>
                        {this.props.standfirst}
                    </div>
                );
            } else {
                standfirst = (
                    <h4 className='caslon h4 md-h9 lg-h4 sm-col-9 md-col-7 mx-auto'
                        dangerouslySetInnerHTML={{__html: util.try(() => this.props.standfirst)}} />
                );
            }
        }

        return (
            <div className='bg-pastel-blue blue clearfix'>
                <div className='container center px2 sm-px5 md-px7'>
                    <div
                        className='caps h7 mt6 sm-mt7 mb4 sm-col-8 mx-auto'>
                        <a
                            className='border-bottom border-accent-green'
                            href={this.props.typeLink}>
                            {util.try(() => this.props.type)}
                        </a>
                    </div>
                    <h1
                        className='caslon my5 sm-col-8 md-col-6 mx-auto line-height-7'
                        dangerouslySetInnerHTML={{__html: util.try(() => this.props.title)}} />
                    <div className='relative sm-mb7'>
                        {standfirst}
                        <div className='sm-show absolute right-0 bottom-0 mb1'>
                            <ShareButton
                                title={util.try(() => this.props.title)}
                                url={util.try(() => this.props.paths.site + this.props.paths.path)} />
                        </div>
                    </div>
                    <div className='sm-hide inline-block mb5 mt2'>
                        <ShareButton
                            title={util.try(() => this.props.title)}
                            url={util.try(() => this.props.paths.site + this.props.paths.path)} />
                    </div>
                </div>
            </div>
        );
    }
}

module.exports = Standfirst;
