/*
 * Journal Module Switcher
 */

import React from 'react';
import util from '../../utilities';
import Image from '../../partials/images/image.jsx';

class ModuleSwitcher extends React.Component {

    render() {

        const blocks = util.try(() => this.props.content.map((block, i) => {

            let component
            switch(block.acf_fc_layout) {
            case 'full_width_image':
                component = (
                    <div className='py2'>
                        <div className='relative'>
                            <Image
                                image={block.image}
                                className='block' />
                            {util.try(() => block.image.caption) &&
                                <div
                                    className='absolute bottom-0 right-0 py1 px2 bg-overlay white h8 caps'
                                    dangerouslySetInnerHTML={{__html:block.image.caption}} />
                            }

                        </div>

                        {util.try(() => block.image_text) &&
                            <div className='sm-col-9 md-col-7 mx-auto'>
                                <div
                                    className='py7 sm-py6 md-py9 h4 caslon center rte'
                                    dangerouslySetInnerHTML={{__html:block.image_text}} />
                            </div>
                        }
                    </div>
                );
                break;
            case 'text_content_2_column':
                component = (
                    <div className='clearfix caslon pt6 pb7 md-py8'>
                        <div className='col col-12 sm-col-6 px2 md-px6'>
                            <div
                                className='md-pl7 rte'
                                dangerouslySetInnerHTML={{__html:block.column_1}} />
                        </div>
                        <div className='col col-12 sm-col-6 px2 sm-pl5 md-pl6 rte'>
                            <div
                                className='md-pr7 rte'
                                dangerouslySetInnerHTML={{__html:block.column_2}} />
                        </div>
                    </div>
                );
                break;
            case 'quote_block':
                component = (
                    <div className='clearfix center'>
                        <div className='md-px9'>
                            <div className='py7 sm-py8 md-py9 sm-px7 md-px9'>
                                <div
                                    className='h1 rte bold'
                                    dangerouslySetInnerHTML={{__html: block.quote}} />
                                {util.try(() => block.credit) &&
                                    <div
                                        className='h8 rte caps'
                                        dangerouslySetInnerHTML={{__html: block.credit}} />
                                }
                            </div>
                        </div>
                    </div>
                );
                break;
            case 'gallery':
                component = (
                    <div className='clearfix py2'>
                        {util.try(() => block.images[0]) &&
                            <div className='col col-12 md-col-6 md-pl8 md-pr9 md-pt8 pt6 pb8'>
                                <Image image={block.images[0]} className='col-12' />
                                {block.images[0].caption &&
                                    <div
                                        className='h7 caps center pt5 sm-pt6'
                                        dangerouslySetInnerHTML={{__html: block.images[0].caption}} />
                                }
                            </div>
                        }
                        {util.try(() => block.images[1]) &&
                            <div className='col col-12 md-col-6 md-pr8 md-pl9 md-pt8 pb8'>
                                <Image image={block.images[1]} className='' />
                                {block.images[1].caption &&
                                    <div
                                        className='h7 caps center pt5 sm-pt6'
                                        dangerouslySetInnerHTML={{__html: block.images[1].caption}} />
                                }
                            </div>
                        }
                    </div>
                );
                break;
            case 'text_content_1_column':
                component = (
                    <div
                        className='px2 pt7 pb5 sm-pt8 sm-pb9 rte  sm-col-8 md-col-6 mx-auto caslon'
                        dangerouslySetInnerHTML={{__html:block.content}} />
                );
                break;
            };

            const bgColor = 'bg-pastel-' + block.background_colour;
            const fillColor = block.fill_entire_background ? 'bg-pastel-' + block.background_colour : 'bg-pastel-blue';

            return (
                <div
                    key={i}
                    className={fillColor}>
                    <div className={'container px2 ' + bgColor}>
                        {component}
                    </div>
                </div>
            );

        }));


        return (
            <div className='blue'>
                {blocks}
            </div>
        );

    }

};

module.exports = ModuleSwitcher;
