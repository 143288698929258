/*
 * Error page
 */

import React from 'react';
import util from '../utilities';
import Page from '../partials/layout/page.jsx';
import Header from '../partials/global/header.jsx';
import Footer from '../partials/global/footer.jsx';

/**
 * Class representing the error pages
 * @extends React.component
 * @prop {object} page
 * @prop {object} settings
 */
class ErrorPage extends React.Component {

    render() {

        const message = this.props.err.message || 'Page not found.';

        return (
            <Page settings={this.props.settings}>

                <Header
                    settings={util.try(() => this.props.settings)} />

                <div className='pt4 sm-pt5 pb4 sm-pb5 px3 sm-px5 center charcoal-001'>
                    <h3
                        className='bold m0 mb2'
                        dangerouslySetInnerHTML={{__html:this.props.err.status}} />
                    <p
                        className='letter-spacing-3'
                        dangerouslySetInnerHTML={{__html: message}} />
                </div>
                <div className='mr3 ml3 mt7 mb7 center'>
                    <a
                        href='/'
                        className='border-bottom letter-spacing-3'>
                        Back to Home
                    </a>
                </div>


                <Footer settings={util.try(() => this.props.settings)} />

            </Page>
        );

    }

};

module.exports = ErrorPage;
