import React from 'react';
import util from '../../utilities';


class CallToAction extends React.Component {

    render() {
        const callToAction = (
            <div className={`clearfix mt3 md-mt9 white relative ${util.try(() => this.props.link) ? 'nudge-right' : ''}`}>
                <div className='mb0 mt2 sm-mt3 md-mr5 md-ml5 md-mtn2px lg-absolute left-0 top-0'>
                    <i className={`icon-arrow-right nudge ${util.try(() => this.props.link) ? 'nudge-right' : ''}`} />
                </div>
                <div className='overflow-hidden'>
                    <h4
                        className='mt1 md-mb5 md-pl5 lg-pl9 line-height-1 md-h3'
                        dangerouslySetInnerHTML={{__html: this.props.callToAction}} />
                    {this.props.buttonLabel &&
                        <div className='md-pl5 lg-pl9'>
                            <div className='col caps h7 line-height-5 bg-accent-green cursor-pointer py1 px2 black'>
                                {this.props.buttonLabel}
                            </div>
                        </div>
                    }
                </div>
            </div>
        );

        const link = this.props.link
            ? <a href={this.props.link}>{callToAction}</a>
            : callToAction;

        return (
            <div className='clearfix px5 md-px0'>
                {link}
            </div>
        );
    }
}

module.exports = CallToAction;
