module.exports = {
    home: require('./home.jsx'),
    journalDetail: require('./journal/detail.jsx'),
    journalListing: require('./journal/listing.jsx'),
    businessDetail: require('./businesses/detail.jsx'),
    businessListing: require('./businesses/listing.jsx'),
    eventDetail: require('./events/detail.jsx'),
    eventsListing: require('./events/listing.jsx'),
    visitors: require('./visitors.jsx'),
    tags: require('./tags.jsx'),
    search: require('./search.jsx'),
    tradersMap: require('./traders-map.jsx'),
    newsletter: require('./newsletter.jsx'),
    genericPage: require('./generic-page.jsx'),
    err: require('./err.jsx')
};
