import React from 'react';

class TraderMap extends React.Component {

    render() {
        return (
            <svg x="0px" y="0px" viewBox="0 0 397.4 425.8" style={{enableBackground: "new 0 0 397.4 425.8"}}>
                <g className="cls-2">
                    <path className="cls-3" d="M323.15,355.05H102a2.83,2.83,0,0,1-2.83-2.83V102.77A2.83,2.83,0,0,1,102,99.94H323.15a2.83,2.83,0,0,1,2.83,2.83V352.22a2.83,2.83,0,0,1-2.83,2.83" transform="translate(0.25 -0.47)"/>
                    <rect className="cls-4" x="99.46" y="99.46" width="226.77" height="255.12" rx="2.83" ry="2.83"/>
                    <path className="cls-3" d="M309,340.88H286.3a2.84,2.84,0,0,1-2.84-2.84V315.37a2.84,2.84,0,0,1,2.84-2.84H309a2.84,2.84,0,0,1,2.84,2.84V338a2.84,2.84,0,0,1-2.84,2.84" transform="translate(0.25 -0.47)"/>
                    <rect className="cls-5" x="283.71" y="312.06" width="28.35" height="28.35" rx="2.83" ry="2.83"/>
                </g>
                <text className="cls-6" transform="translate(287.38 322.92)">55</text>
                <g className="cls-2">
                    <path className="cls-3" d="M309,142.45H286.3a2.84,2.84,0,0,1-2.84-2.83V116.94a2.84,2.84,0,0,1,2.84-2.83H309a2.84,2.84,0,0,1,2.84,2.83v22.68a2.84,2.84,0,0,1-2.84,2.83" transform="translate(0.25 -0.47)"/>
                    <rect className="cls-5" x="283.71" y="113.64" width="28.35" height="28.35" rx="2.84" ry="2.84"/>
                </g>
                <text className="cls-6" transform="translate(287.38 125.08)">56</text>
                <g className="cls-2">
                    <path className="cls-3" d="M138.9,340.88H116.22a2.84,2.84,0,0,1-2.83-2.84V315.37a2.84,2.84,0,0,1,2.83-2.84H138.9a2.84,2.84,0,0,1,2.83,2.84V338a2.84,2.84,0,0,1-2.83,2.84" transform="translate(0.25 -0.47)"/>
                    <rect className="cls-5" x="113.64" y="312.06" width="28.35" height="28.35" rx="2.84" ry="2.84"/>
                </g>
                <text className="cls-6" transform="translate(117 322.92)">48</text>
                <g className="cls-2">
                    <path className="cls-3" d="M138.9,142.45H116.22a2.83,2.83,0,0,1-2.83-2.83V116.94a2.83,2.83,0,0,1,2.83-2.83H138.9a2.83,2.83,0,0,1,2.83,2.83v22.68a2.83,2.83,0,0,1-2.83,2.83" transform="translate(0.25 -0.47)"/>
                    <rect className="cls-5" x="113.64" y="113.64" width="28.35" height="28.35" rx="2.84" ry="2.84"/>
                </g>
                <text className="cls-6" transform="translate(117 125.08)">63</text>
                <g className="cls-2">
                    <path className="cls-9" d="M25.51,57.42H2.83A2.84,2.84,0,0,1,0,54.58v-51A2.84,2.84,0,0,1,2.83.72H25.51a2.84,2.84,0,0,1,2.83,2.84v51a2.84,2.84,0,0,1-2.83,2.84" transform="translate(0.25 -0.47)"/>
                    <path className="cls-10" d="M3.08.25H25.76A2.84,2.84,0,0,1,28.6,3.09v51a2.83,2.83,0,0,1-2.83,2.83H3.09A2.84,2.84,0,0,1,.25,54.11v-51A2.83,2.83,0,0,1,3.08.25Z"/>
                </g>
                <text className="cls-11" transform="translate(3.62 11.7)">38</text>
                <g className="cls-2">
                    <path className="cls-12" d="M82.2,57.42H59.52a2.84,2.84,0,0,1-2.83-2.84v-51A2.84,2.84,0,0,1,59.52.72H82.2A2.84,2.84,0,0,1,85,3.56v51a2.84,2.84,0,0,1-2.84,2.84" transform="translate(0.25 -0.47)"/>
                    <rect className="cls-10" x="56.94" y="0.25" width="28.35" height="56.69" rx="2.83" ry="2.83"/>
                </g>
                <text className="cls-13" transform="translate(60.59 11.7)">37</text>
                <g className="cls-2">
                    <path className="cls-9" d="M110.55,57.42H87.87A2.83,2.83,0,0,1,85,54.58v-51A2.83,2.83,0,0,1,87.87.72h22.68a2.84,2.84,0,0,1,2.83,2.84v51a2.84,2.84,0,0,1-2.83,2.84" transform="translate(0.25 -0.47)"/>
                    <path className="cls-10" d="M88.12.25H110.8a2.84,2.84,0,0,1,2.84,2.84v51a2.83,2.83,0,0,1-2.83,2.83H88.12a2.83,2.83,0,0,1-2.83-2.83v-51A2.83,2.83,0,0,1,88.12.25Z"/>
                </g>
                <text className="cls-11" transform="translate(88.65 11.7)">36</text>
                <g className="cls-2">
                    <path className="cls-9" d="M138.89,57.42H116.22a2.84,2.84,0,0,1-2.84-2.84v-51A2.84,2.84,0,0,1,116.22.72h22.67a2.84,2.84,0,0,1,2.84,2.84v51a2.84,2.84,0,0,1-2.84,2.84" transform="translate(0.25 -0.47)"/>
                    <path className="cls-10" d="M116.47.25h22.68A2.84,2.84,0,0,1,142,3.09v51a2.84,2.84,0,0,1-2.84,2.84H116.47a2.83,2.83,0,0,1-2.83-2.83v-51A2.83,2.83,0,0,1,116.47.25Z"/>
                </g>
                <text className="cls-11" transform="translate(117 11.7)">35</text>
                <g className="cls-2">
                    <path className="cls-12" d="M167.24,57.42H144.56a2.84,2.84,0,0,1-2.83-2.84v-51A2.84,2.84,0,0,1,144.56.72h22.68a2.83,2.83,0,0,1,2.83,2.84v51a2.83,2.83,0,0,1-2.83,2.84" transform="translate(0.25 -0.47)"/>
                    <rect className="cls-10" x="141.98" y="0.25" width="28.35" height="56.69" rx="2.83" ry="2.83"/>
                </g>
                <text className="cls-11" transform="translate(145.35 11.11)">34</text>
                <g className="cls-2">
                    <path className="cls-12" d="M223.93,57.42H201.26a2.84,2.84,0,0,1-2.84-2.84v-51A2.84,2.84,0,0,1,201.26.72h22.67a2.84,2.84,0,0,1,2.84,2.84v51a2.84,2.84,0,0,1-2.84,2.84" transform="translate(0.25 -0.47)"/>
                    <path className="cls-10" d="M201.51.25h22.68A2.83,2.83,0,0,1,227,3.09v51a2.83,2.83,0,0,1-2.83,2.83H201.51a2.84,2.84,0,0,1-2.84-2.84v-51A2.83,2.83,0,0,1,201.51.25Z"/>
                </g>
                <text className="cls-14" transform="translate(202.9 11.11)">28</text>
                <g className="cls-2">
                    <path className="cls-12" d="M252.28,57.42H229.6a2.84,2.84,0,0,1-2.83-2.84v-51A2.84,2.84,0,0,1,229.6.72h22.68a2.83,2.83,0,0,1,2.83,2.84v51a2.83,2.83,0,0,1-2.83,2.84" transform="translate(0.25 -0.47)"/>
                    <rect className="cls-10" x="227.02" y="0.25" width="28.35" height="56.69" rx="2.83" ry="2.83"/>
                </g>
                <text className="cls-14" transform="translate(230.97 11.11)">27</text>
                <g className="cls-2">
                    <path className="cls-12" d="M280.63,57.42H258a2.84,2.84,0,0,1-2.84-2.84v-51A2.84,2.84,0,0,1,258,.72h22.68a2.84,2.84,0,0,1,2.83,2.84v51a2.84,2.84,0,0,1-2.83,2.84" transform="translate(0.25 -0.47)"/>
                    <rect className="cls-10" x="255.37" y="0.25" width="28.35" height="56.69" rx="2.83" ry="2.83"/>
                </g>
                <text className="cls-11" transform="translate(259.31 11.11)">26</text>
                <g className="cls-2">
                    <path className="cls-12" d="M309,57.42H286.3a2.84,2.84,0,0,1-2.84-2.84v-51A2.84,2.84,0,0,1,286.3.72H309a2.84,2.84,0,0,1,2.84,2.84v51A2.84,2.84,0,0,1,309,57.42" transform="translate(0.25 -0.47)"/>
                    <rect className="cls-10" x="283.71" y="0.25" width="28.35" height="56.69" rx="2.83" ry="2.83"/>
                </g>
                <text className="cls-14" transform="translate(287.66 11.11)">25</text>
                <g className="cls-2">
                    <path className="cls-12" d="M337.32,57.46H314.65a2.83,2.83,0,0,1-2.84-2.83V3.56A2.84,2.84,0,0,1,314.65.72h22.67a2.84,2.84,0,0,1,2.84,2.84V54.63a2.83,2.83,0,0,1-2.84,2.83" transform="translate(0.25 -0.47)"/>
                    <rect className="cls-10" x="312.06" y="0.25" width="28.35" height="56.74" rx="2.83" ry="2.83"/>
                </g>
                <text className="cls-11" transform="translate(316.01 11.11)">24</text>
                <g className="cls-2">
                    <path className="cls-12" d="M25.51,425.94H2.84A2.84,2.84,0,0,1,0,423.11v-51a2.84,2.84,0,0,1,2.84-2.84H25.51a2.84,2.84,0,0,1,2.84,2.84v51a2.84,2.84,0,0,1-2.84,2.83" transform="translate(0.25 -0.47)"/>
                    <rect className="cls-10" x="0.25" y="368.78" width="28.35" height="56.69" rx="2.83" ry="2.83"/>
                </g>
                <text className="cls-14" transform="translate(3.62 379.64)">1</text>
                <g className="cls-2">
                    <path className="cls-12" d="M53.85,425.94H31.18a2.84,2.84,0,0,1-2.84-2.83v-51a2.84,2.84,0,0,1,2.84-2.84H53.85a2.84,2.84,0,0,1,2.84,2.84v51a2.84,2.84,0,0,1-2.84,2.83" transform="translate(0.25 -0.47)"/>
                    <path className="cls-10" d="M31.43,368.78H54.11a2.84,2.84,0,0,1,2.84,2.84v51a2.83,2.83,0,0,1-2.83,2.83H31.43a2.83,2.83,0,0,1-2.83-2.83v-51A2.83,2.83,0,0,1,31.43,368.78Z"/>
                </g>
                <text className="cls-14" transform="translate(32.54 379.64)">2</text>
                <g className="cls-2">
                    <path className="cls-12" d="M82.2,425.94H59.52a2.83,2.83,0,0,1-2.83-2.83v-51a2.84,2.84,0,0,1,2.83-2.84H82.2A2.84,2.84,0,0,1,85,372.09v51a2.84,2.84,0,0,1-2.84,2.83" transform="translate(0.25 -0.47)"/>
                    <rect className="cls-10" x="56.94" y="368.78" width="28.35" height="56.69" rx="2.83" ry="2.83"/>
                </g>
                <text className="cls-14" transform="translate(60.64 379.64)">3</text>
                <g className="cls-2">
                    <path className="cls-12" d="M110.55,425.94H87.87A2.83,2.83,0,0,1,85,423.11v-51a2.83,2.83,0,0,1,2.83-2.84h22.68a2.84,2.84,0,0,1,2.83,2.84v51a2.83,2.83,0,0,1-2.83,2.83" transform="translate(0.25 -0.47)"/>
                    <path className="cls-10" d="M88.12,368.78H110.8a2.84,2.84,0,0,1,2.84,2.84v51a2.83,2.83,0,0,1-2.83,2.83H88.12a2.83,2.83,0,0,1-2.83-2.83v-51A2.83,2.83,0,0,1,88.12,368.78Z"/>
                </g>
                <text className="cls-14" transform="translate(88.65 379.64)">4</text>
                <g className="cls-2">
                    <path className="cls-12" d="M167.24,425.94h-51a2.84,2.84,0,0,1-2.84-2.83v-51a2.84,2.84,0,0,1,2.84-2.84h51a2.83,2.83,0,0,1,2.83,2.84v51a2.83,2.83,0,0,1-2.83,2.83" transform="translate(0.25 -0.47)"/>
                    <rect className="cls-17" x="113.64" y="368.78" width="56.69" height="56.69" rx="2.83" ry="2.83"/>
                </g>
                <text className="cls-14" transform="translate(117 379.61)">5</text>
                <g className="cls-2">
                    <path className="cls-12" d="M368.5,230.33V253a2.84,2.84,0,0,0,2.84,2.83H394a2.83,2.83,0,0,0,2.83-2.83V230.33a2.84,2.84,0,0,0-2.83-2.84H371.34a2.84,2.84,0,0,0-2.84,2.84" transform="translate(0.25 -0.47)"/>
                    <path className="cls-18" d="M371.59,227h22.68a2.83,2.83,0,0,1,2.83,2.83v22.68a2.83,2.83,0,0,1-2.83,2.83H371.59a2.83,2.83,0,0,1-2.83-2.83V229.86A2.84,2.84,0,0,1,371.59,227Z"/>
                </g>
                <text className="cls-14" transform="translate(373.04 237.88)">15</text>
                <g className="cls-2">
                    <path className="cls-12" d="M340.16,258.67v22.68a2.84,2.84,0,0,0,2.83,2.84h22.68a2.84,2.84,0,0,0,2.83-2.84V258.67a2.83,2.83,0,0,0-2.83-2.83H343a2.83,2.83,0,0,0-2.83,2.83" transform="translate(0.25 -0.47)"/>
                    <rect className="cls-18" x="340.41" y="255.37" width="28.35" height="28.35" rx="2.83" ry="2.83"/>
                </g>
                <text className="cls-14" transform="translate(344.36 266.23)">16</text>
                <g className="cls-2">
                    <path className="cls-12" d="M368.5,258.67v22.68a2.84,2.84,0,0,0,2.84,2.84H394a2.84,2.84,0,0,0,2.83-2.84V258.67a2.83,2.83,0,0,0-2.83-2.83H371.34a2.84,2.84,0,0,0-2.84,2.83" transform="translate(0.25 -0.47)"/>
                    <path className="cls-18" d="M371.59,255.37h22.68a2.83,2.83,0,0,1,2.83,2.83v22.68a2.83,2.83,0,0,1-2.83,2.83H371.59a2.83,2.83,0,0,1-2.83-2.83V258.2A2.84,2.84,0,0,1,371.59,255.37Z"/>
                </g>
                <text className="cls-14" transform="translate(373.04 266.23)">14</text>
                <g className="cls-2">
                    <path className="cls-12" d="M340.16,287V309.7a2.83,2.83,0,0,0,2.83,2.83h51a2.83,2.83,0,0,0,2.83-2.83V287a2.83,2.83,0,0,0-2.83-2.83H343a2.83,2.83,0,0,0-2.83,2.83" transform="translate(0.25 -0.47)"/>
                    <rect className="cls-10" x="340.41" y="283.71" width="56.69" height="28.35" rx="2.83" ry="2.83"/>
                </g>
                <text className="cls-14" transform="translate(344.36 295.16)">13</text>
                <g className="cls-2">
                    <path className="cls-9" d="M0,131.12V168a2.83,2.83,0,0,0,2.83,2.83h51A2.83,2.83,0,0,0,56.69,168V131.12a2.84,2.84,0,0,0-2.83-2.84h-51A2.84,2.84,0,0,0,0,131.12" transform="translate(0.25 -0.47)"/>
                    <rect className="cls-10" x="0.25" y="127.81" width="56.69" height="42.52" rx="2.83" ry="2.83"/>
                </g>
                <text className="cls-20" transform="translate(32.54 139.26)">41</text>
                <g className="cls-2">
                    <path className="cls-9" d="M340.16,88.6v22.67a2.84,2.84,0,0,0,2.83,2.84h22.68a2.84,2.84,0,0,0,2.83-2.84V88.6a2.84,2.84,0,0,0-2.83-2.84H343a2.84,2.84,0,0,0-2.83,2.84" transform="translate(0.25 -0.47)"/>
                    <rect className="cls-18" x="340.41" y="85.29" width="28.35" height="28.35" rx="2.83" ry="2.83"/>
                </g>
                <text className="cls-11" transform="translate(344.36 96.69)">20</text>
                <g className="cls-2">
                    <path className="cls-12" d="M368.5,88.6v22.67a2.84,2.84,0,0,0,2.84,2.84H394a2.84,2.84,0,0,0,2.83-2.84V88.6A2.84,2.84,0,0,0,394,85.76H371.34a2.84,2.84,0,0,0-2.84,2.84" transform="translate(0.25 -0.47)"/>
                    <path className="cls-10" d="M371.59,85.29h22.68a2.83,2.83,0,0,1,2.83,2.83V110.8a2.83,2.83,0,0,1-2.83,2.83H371.59a2.83,2.83,0,0,1-2.83-2.83V88.13a2.84,2.84,0,0,1,2.84-2.84Z"/>
                </g>
                <text className="cls-14" transform="translate(373.04 96.69)">19</text>
                <g className="cls-2">
                    <path className="cls-12" d="M340,145.29v51a2.84,2.84,0,0,0,2.83,2.84h51a2.84,2.84,0,0,0,2.84-2.84v-51a2.84,2.84,0,0,0-2.84-2.84h-51a2.84,2.84,0,0,0-2.83,2.84" transform="translate(0.25 -0.47)"/>
                    <rect className="cls-10" x="340.24" y="141.98" width="56.69" height="56.69" rx="2.83" ry="2.83"/>
                </g>
                <text className="cls-14" transform="translate(344.36 152.84)">18</text>
                <g className="cls-2">
                    <path className="cls-12" d="M340.16,60.25V82.93A2.83,2.83,0,0,0,343,85.76h51a2.83,2.83,0,0,0,2.83-2.83V60.25A2.83,2.83,0,0,0,394,57.42H343a2.83,2.83,0,0,0-2.83,2.83" transform="translate(0.25 -0.47)"/>
                    <rect className="cls-10" x="340.41" y="56.94" width="56.69" height="28.35" rx="2.83" ry="2.83"/>
                </g>
                <text className="cls-21" transform="translate(344.36 68.39)">21</text>
                <g className="cls-2">
                    <path className="cls-12" d="M365.67,29.07H343a2.84,2.84,0,0,1-2.83-2.84V3.56A2.84,2.84,0,0,1,343,.72h22.68a2.84,2.84,0,0,1,2.83,2.84V26.23a2.84,2.84,0,0,1-2.83,2.84" transform="translate(0.25 -0.47)"/>
                    <rect className="cls-10" x="340.41" y="0.25" width="28.35" height="28.35" rx="2.83" ry="2.83"/>
                </g>
                <text className="cls-11" transform="translate(344.36 11.7)">23</text>
                <g className="cls-2">
                    <path className="cls-12" d="M368.5,3.56V26.23a2.84,2.84,0,0,1-2.83,2.84H343a2.83,2.83,0,0,0-2.83,2.83V54.58A2.84,2.84,0,0,0,343,57.42h51a2.83,2.83,0,0,0,2.83-2.83v-51A2.84,2.84,0,0,0,394,.72H371.34a2.84,2.84,0,0,0-2.84,2.84" transform="translate(0.25 -0.47)"/>
                    <path className="cls-10" d="M368.5,3.56V26.23a2.84,2.84,0,0,1-2.83,2.84H343a2.83,2.83,0,0,0-2.83,2.83V54.58A2.84,2.84,0,0,0,343,57.42h51a2.83,2.83,0,0,0,2.83-2.83v-51A2.84,2.84,0,0,0,394,.72H371.34A2.84,2.84,0,0,0,368.5,3.56Z" transform="translate(0.25 -0.47)"/>
                </g>
                <text className="cls-11" transform="translate(373.04 11.7)">22</text>
                <g className="cls-2">
                    <path className="cls-12" d="M340.16,343.71v22.68a2.84,2.84,0,0,0,2.83,2.84h51a2.84,2.84,0,0,0,2.83-2.84V343.71a2.83,2.83,0,0,0-2.83-2.83H343a2.83,2.83,0,0,0-2.83,2.83" transform="translate(0.25 -0.47)"/>
                    <rect className="cls-10" x="340.41" y="340.41" width="56.69" height="28.35" rx="2.83" ry="2.83"/>
                </g>
                <text className="cls-14" transform="translate(344.36 351.86)">12</text>
                <g className="cls-2">
                    <path className="cls-12" d="M0,88.6v36.85a2.83,2.83,0,0,0,2.83,2.83h51a2.83,2.83,0,0,0,2.83-2.83V88.6a2.84,2.84,0,0,0-2.83-2.84h-51A2.84,2.84,0,0,0,0,88.6" transform="translate(0.25 -0.47)"/>
                    <rect className="cls-10" x="0.25" y="85.29" width="56.69" height="42.52" rx="2.83" ry="2.83"/>
                </g>
                <text className="cls-11" transform="translate(4.21 96.15)">39</text>
                <g className="cls-2">
                    <path className="cls-9" d="M25.51,170.8H2.84A2.84,2.84,0,0,1,0,168V131.12a2.84,2.84,0,0,1,2.84-2.84H25.51a2.84,2.84,0,0,1,2.84,2.84V168a2.84,2.84,0,0,1-2.84,2.83" transform="translate(0.25 -0.47)"/>
                    <rect className="cls-10" x="0.25" y="127.81" width="28.35" height="42.52" rx="2.83" ry="2.83"/>
                </g>
                <text className="cls-11" transform="translate(4.21 138.67)">40</text>
                <g className="cls-2">
                    <path className="cls-9" d="M0,216.16V253a2.83,2.83,0,0,0,2.83,2.83h51A2.83,2.83,0,0,0,56.69,253V216.16a2.84,2.84,0,0,0-2.83-2.84h-51A2.84,2.84,0,0,0,0,216.16" transform="translate(0.25 -0.47)"/>
                    <rect className="cls-10" x="0.25" y="212.85" width="56.69" height="42.52" rx="2.83" ry="2.83"/>
                </g>
                <text className="cls-14" transform="translate(32.54 224.3)">44</text>
                <g className="cls-2">
                    <path className="cls-9" d="M223.93,85.76H201.26a2.84,2.84,0,0,1-2.84-2.83V60.25a2.83,2.83,0,0,1,2.84-2.83h22.67a2.83,2.83,0,0,1,2.84,2.83V82.93a2.84,2.84,0,0,1-2.84,2.83" transform="translate(0.25 -0.47)"/>
                    <path className="cls-18" d="M201.51,56.94h22.68A2.84,2.84,0,0,1,227,59.78V82.45a2.83,2.83,0,0,1-2.83,2.83H201.51a2.84,2.84,0,0,1-2.84-2.84V59.78A2.84,2.84,0,0,1,201.51,56.94Z"/>
                </g>
                <text className="cls-11" transform="translate(202.9 68.4)">33</text>
                <g className="cls-2">
                    <path className="cls-9" d="M252.28,85.76H229.6a2.83,2.83,0,0,1-2.83-2.83V60.25a2.83,2.83,0,0,1,2.83-2.83h22.68a2.83,2.83,0,0,1,2.83,2.83V82.93a2.83,2.83,0,0,1-2.83,2.83" transform="translate(0.25 -0.47)"/>
                    <path className="cls-18" d="M229.86,56.94h22.67a2.84,2.84,0,0,1,2.84,2.84V82.45a2.83,2.83,0,0,1-2.83,2.83H229.86A2.84,2.84,0,0,1,227,82.45V59.78A2.84,2.84,0,0,1,229.86,56.94Z"/>
                </g>
                <text className="cls-14" transform="translate(230.97 68.4)">32</text>
                <g className="cls-2">
                    <path className="cls-9" d="M280.62,85.76H258a2.84,2.84,0,0,1-2.84-2.83V60.25A2.84,2.84,0,0,1,258,57.42h22.67a2.83,2.83,0,0,1,2.84,2.83V82.93a2.84,2.84,0,0,1-2.84,2.83" transform="translate(0.25 -0.47)"/>
                    <path className="cls-18" d="M258.2,56.94h22.68a2.84,2.84,0,0,1,2.84,2.84V82.45a2.83,2.83,0,0,1-2.83,2.83H258.2a2.84,2.84,0,0,1-2.84-2.84V59.78A2.84,2.84,0,0,1,258.2,56.94Z"/>
                </g>
                <text className="cls-14" transform="translate(259.31 68.4)">31</text>
                <g className="cls-2">
                    <path className="cls-9" d="M309,85.76H286.3a2.84,2.84,0,0,1-2.84-2.83V60.25a2.83,2.83,0,0,1,2.84-2.83H309a2.83,2.83,0,0,1,2.84,2.83V82.93A2.84,2.84,0,0,1,309,85.76" transform="translate(0.25 -0.47)"/>
                    <path className="cls-18" d="M286.55,56.94h22.68a2.84,2.84,0,0,1,2.84,2.84V82.45a2.83,2.83,0,0,1-2.83,2.83H286.55a2.84,2.84,0,0,1-2.84-2.84V59.78A2.84,2.84,0,0,1,286.55,56.94Z"/>
                </g>
                <text className="cls-14" transform="translate(287.66 68.4)">30</text>
                <g className="cls-2">
                    <path className="cls-9" d="M337.32,85.76H314.65a2.84,2.84,0,0,1-2.84-2.83V60.25a2.83,2.83,0,0,1,2.84-2.83h22.67a2.83,2.83,0,0,1,2.84,2.83V82.93a2.84,2.84,0,0,1-2.84,2.83" transform="translate(0.25 -0.47)"/>
                    <rect className="cls-18" x="312.06" y="56.94" width="28.35" height="28.35" rx="2.83" ry="2.83"/>
                </g>
                <text className="cls-11" transform="translate(316.01 68.4)">29</text>
                <g className="cls-2">
                    <path className="cls-9" d="M340.16,230.33V253a2.83,2.83,0,0,0,2.83,2.83h22.68A2.83,2.83,0,0,0,368.5,253V230.33a2.84,2.84,0,0,0-2.83-2.84H343a2.84,2.84,0,0,0-2.83,2.84" transform="translate(0.25 -0.47)"/>
                    <rect className="cls-18" x="340.41" y="227.02" width="28.35" height="28.35" rx="2.83" ry="2.83"/>
                </g>
                <text className="cls-14" transform="translate(344.36 237.88)">17</text>
                <g className="cls-2">
                    <path className="cls-23" d="M195.59,199.15H172.92a2.84,2.84,0,0,1-2.84-2.84V173.64a2.84,2.84,0,0,1,2.84-2.84h22.67a2.84,2.84,0,0,1,2.84,2.84v22.67a2.84,2.84,0,0,1-2.84,2.84" transform="translate(0.25 -0.47)"/>
                    <rect className="cls-24" x="170.34" y="170.33" width="28.35" height="28.35" rx="2.83" ry="2.83"/>
                </g>
                <text className="cls-11" transform="translate(173.7 181.19)">64</text>
                <g className="cls-2">
                    <path className="cls-23" d="M223.94,199.15H201.26a2.83,2.83,0,0,1-2.83-2.84V173.64a2.83,2.83,0,0,1,2.83-2.84h22.68a2.84,2.84,0,0,1,2.83,2.84v22.67a2.84,2.84,0,0,1-2.83,2.84" transform="translate(0.25 -0.47)"/>
                    <path className="cls-24" d="M201.51,170.33h22.68a2.83,2.83,0,0,1,2.83,2.83v22.68a2.83,2.83,0,0,1-2.83,2.83H201.52a2.84,2.84,0,0,1-2.84-2.84V173.16A2.83,2.83,0,0,1,201.51,170.33Z"/>
                </g>
                <text className="cls-14" transform="translate(202.9 181.19)">73</text>
                <g className="cls-2">
                    <path className="cls-23" d="M252.29,199.15H229.61a2.84,2.84,0,0,1-2.84-2.84V173.64a2.84,2.84,0,0,1,2.84-2.84h22.68a2.84,2.84,0,0,1,2.83,2.84v22.67a2.84,2.84,0,0,1-2.83,2.84" transform="translate(0.25 -0.47)"/>
                    <rect className="cls-24" x="227.03" y="170.33" width="28.35" height="28.35" rx="2.83" ry="2.83"/>
                </g>
                <text className="cls-14" transform="translate(231.25 181.19)">72</text>
                <g className="cls-2">
                    <path className="cls-23" d="M195.59,284.19H172.92a2.84,2.84,0,0,1-2.84-2.84V258.67a2.84,2.84,0,0,1,2.84-2.83h22.67a2.84,2.84,0,0,1,2.84,2.83v22.68a2.84,2.84,0,0,1-2.84,2.84" transform="translate(0.25 -0.47)"/>
                    <rect className="cls-24" x="170.34" y="255.37" width="28.35" height="28.35" rx="2.83" ry="2.83"/>
                </g>
                <text className="cls-13" transform="translate(173.99 266.23)">67</text>
                <g className="cls-2">
                    <path className="cls-26" d="M181.42,156.63h-8.51a2.84,2.84,0,0,1-2.83-2.84V131.12a2.84,2.84,0,0,1,2.83-2.84h8.51a2.84,2.84,0,0,1,2.83,2.84v22.67a2.84,2.84,0,0,1-2.83,2.84" transform="translate(0.25 -0.47)"/>
                    <rect className="cls-27" x="170.33" y="127.81" width="14.17" height="28.35" rx="2.83" ry="2.83"/>
                </g>
                <text className="cls-6" transform="translate(172.27 138.67)">62</text>
                <g className="cls-2">
                    <path className="cls-26" d="M209.76,156.63h-8.5a2.84,2.84,0,0,1-2.84-2.84V131.12a2.84,2.84,0,0,1,2.84-2.84h8.5a2.84,2.84,0,0,1,2.84,2.84v22.67a2.84,2.84,0,0,1-2.84,2.84" transform="translate(0.25 -0.47)"/>
                    <rect className="cls-27" x="198.68" y="127.81" width="14.17" height="28.35" rx="2.83" ry="2.83"/>
                </g>
                <text className="cls-28" transform="translate(200.61 138.67)">60</text>
                <g className="cls-2">
                    <path className="cls-26" d="M195.59,156.63h-8.51a2.84,2.84,0,0,1-2.83-2.84V131.12a2.84,2.84,0,0,1,2.83-2.84h8.51a2.84,2.84,0,0,1,2.83,2.84v22.67a2.84,2.84,0,0,1-2.83,2.84" transform="translate(0.25 -0.47)"/>
                    <rect className="cls-27" x="184.5" y="127.81" width="14.17" height="28.35" rx="2.83" ry="2.83"/>
                </g>
                <text className="cls-28" transform="translate(186.86 138.67)">61</text>
                <g className="cls-2">
                    <path className="cls-26" d="M238.11,156.63H229.6a2.84,2.84,0,0,1-2.83-2.84V131.12a2.84,2.84,0,0,1,2.83-2.84h8.51a2.84,2.84,0,0,1,2.83,2.84v22.67a2.84,2.84,0,0,1-2.83,2.84" transform="translate(0.25 -0.47)"/>
                    <rect className="cls-27" x="227.02" y="127.81" width="14.17" height="28.35" rx="2.83" ry="2.83"/>
                    <path className="cls-26" d="M252.28,156.63h-8.5a2.84,2.84,0,0,1-2.84-2.84V131.12a2.84,2.84,0,0,1,2.84-2.84h8.5a2.83,2.83,0,0,1,2.83,2.84v22.67a2.83,2.83,0,0,1-2.83,2.84" transform="translate(0.25 -0.47)"/>
                    <rect className="cls-27" x="241.19" y="127.81" width="14.17" height="28.35" rx="2.83" ry="2.83"/>
                </g>
                <text className="cls-6" transform="translate(228.96 138.67)">58</text>
                <text className="cls-28" transform="translate(243.41 138.67)">57</text>
                <g className="cls-2">
                    <path className="cls-26" d="M223.93,156.63h-8.5a2.84,2.84,0,0,1-2.84-2.84V131.12a2.84,2.84,0,0,1,2.84-2.84h8.5a2.84,2.84,0,0,1,2.84,2.84v22.67a2.84,2.84,0,0,1-2.84,2.84" transform="translate(0.25 -0.47)"/>
                    <path className="cls-27" d="M215.68,127.81h8.51a2.83,2.83,0,0,1,2.83,2.83v22.68a2.83,2.83,0,0,1-2.83,2.83h-8.5a2.84,2.84,0,0,1-2.84-2.84V130.64A2.83,2.83,0,0,1,215.68,127.81Z"/>
                </g>
                <text className="cls-6" transform="translate(214.79 138.67)">59</text>
                <g className="cls-2">
                    <path className="cls-26" d="M181.42,326.71h-8.51a2.84,2.84,0,0,1-2.83-2.84V301.19a2.83,2.83,0,0,1,2.83-2.83h8.51a2.83,2.83,0,0,1,2.83,2.83v22.68a2.84,2.84,0,0,1-2.83,2.84" transform="translate(0.25 -0.47)"/>
                    <rect className="cls-27" x="170.33" y="297.89" width="14.17" height="28.35" rx="2.83" ry="2.83"/>
                </g>
                <text className="cls-6" transform="translate(172.27 308.75)">49</text>
                <g className="cls-2">
                    <path className="cls-26" d="M209.76,326.71h-8.5a2.84,2.84,0,0,1-2.84-2.84V301.19a2.84,2.84,0,0,1,2.84-2.83h8.5a2.84,2.84,0,0,1,2.84,2.83v22.68a2.84,2.84,0,0,1-2.84,2.84" transform="translate(0.25 -0.47)"/>
                    <rect className="cls-27" x="198.68" y="297.89" width="14.17" height="28.35" rx="2.83" ry="2.83"/>
                </g>
                <text className="cls-30" transform="translate(200.61 308.75)">51</text>
                <g className="cls-2">
                    <path className="cls-26" d="M195.59,326.71h-8.51a2.84,2.84,0,0,1-2.83-2.84V301.19a2.83,2.83,0,0,1,2.83-2.83h8.51a2.83,2.83,0,0,1,2.83,2.83v22.68a2.84,2.84,0,0,1-2.83,2.84" transform="translate(0.25 -0.47)"/>
                    <rect className="cls-27" x="184.5" y="297.89" width="14.17" height="28.35" rx="2.83" ry="2.83"/>
                </g>
                <text className="cls-28" transform="translate(186.44 308.75)">50</text>
                <g className="cls-2">
                    <path className="cls-26" d="M238.11,326.71H229.6a2.84,2.84,0,0,1-2.83-2.84V301.19a2.83,2.83,0,0,1,2.83-2.83h8.51a2.83,2.83,0,0,1,2.83,2.83v22.68a2.84,2.84,0,0,1-2.83,2.84" transform="translate(0.25 -0.47)"/>
                    <rect className="cls-27" x="227.02" y="297.89" width="14.17" height="28.35" rx="2.83" ry="2.83"/>
                    <path className="cls-26" d="M252.28,326.71h-8.5a2.84,2.84,0,0,1-2.84-2.84V301.19a2.84,2.84,0,0,1,2.84-2.83h8.5a2.83,2.83,0,0,1,2.83,2.83v22.68a2.83,2.83,0,0,1-2.83,2.84" transform="translate(0.25 -0.47)"/>
                    <rect className="cls-27" x="241.19" y="297.89" width="14.17" height="28.35" rx="2.83" ry="2.83"/>
                </g>
                <text className="cls-6" transform="translate(228.96 308.75)">53</text>
                <text className="cls-6" transform="translate(243.14 308.75)">54</text>
                <g className="cls-2">
                    <path className="cls-26" d="M223.93,326.71h-8.5a2.84,2.84,0,0,1-2.84-2.84V301.19a2.84,2.84,0,0,1,2.84-2.83h8.5a2.84,2.84,0,0,1,2.84,2.83v22.68a2.84,2.84,0,0,1-2.84,2.84" transform="translate(0.25 -0.47)"/>
                    <path className="cls-27" d="M215.68,297.89h8.5a2.83,2.83,0,0,1,2.83,2.83V323.4a2.83,2.83,0,0,1-2.83,2.83h-8.5a2.84,2.84,0,0,1-2.84-2.84V300.72A2.83,2.83,0,0,1,215.68,297.89Z"/>
                </g>
                <text className="cls-6" transform="translate(214.79 308.75)">52</text>
                <g className="cls-2">
                    <path className="cls-23" d="M223.94,284.19H201.26a2.83,2.83,0,0,1-2.83-2.84V258.67a2.83,2.83,0,0,1,2.83-2.83h22.68a2.83,2.83,0,0,1,2.83,2.83v22.68a2.84,2.84,0,0,1-2.83,2.84" transform="translate(0.25 -0.47)"/>
                    <path className="cls-24" d="M201.51,255.37h22.68A2.83,2.83,0,0,1,227,258.2v22.68a2.83,2.83,0,0,1-2.83,2.83H201.52a2.84,2.84,0,0,1-2.84-2.84V258.2A2.83,2.83,0,0,1,201.51,255.37Z"/>
                </g>
                <text className="cls-11" transform="translate(202.9 266.23)">68</text>
                <g className="cls-2">
                    <path className="cls-23" d="M252.29,284.19H229.61a2.84,2.84,0,0,1-2.84-2.84V258.67a2.84,2.84,0,0,1,2.84-2.83h22.68a2.83,2.83,0,0,1,2.83,2.83v22.68a2.84,2.84,0,0,1-2.83,2.84" transform="translate(0.25 -0.47)"/>
                    <rect className="cls-24" x="227.03" y="255.37" width="28.35" height="28.35" rx="2.83" ry="2.83"/>
                </g>
                <text className="cls-14" transform="translate(230.97 266.23)">69</text>
                <g className="cls-2">
                    <path className="cls-23" d="M195.59,227.49H172.92a2.84,2.84,0,0,1-2.84-2.83V202a2.84,2.84,0,0,1,2.84-2.83h22.67a2.84,2.84,0,0,1,2.84,2.83v22.68a2.84,2.84,0,0,1-2.84,2.83" transform="translate(0.25 -0.47)"/>
                    <rect className="cls-24" x="170.34" y="198.68" width="28.35" height="28.35" rx="2.83" ry="2.83"/>
                </g>
                <text className="cls-11" transform="translate(173.7 209.53)">65</text>
                <g className="cls-2">
                    <path className="cls-23" d="M252.29,227.49H229.61a2.84,2.84,0,0,1-2.84-2.83V202a2.84,2.84,0,0,1,2.84-2.83h22.68a2.83,2.83,0,0,1,2.83,2.83v22.68a2.83,2.83,0,0,1-2.83,2.83" transform="translate(0.25 -0.47)"/>
                    <rect className="cls-24" x="227.03" y="198.68" width="28.35" height="28.35" rx="2.83" ry="2.83"/>
                </g>
                <text className="cls-14" transform="translate(231.58 209.53)">71</text>
                <g className="cls-2">
                    <path className="cls-23" d="M195.59,255.84H172.92a2.84,2.84,0,0,1-2.84-2.83V230.33a2.84,2.84,0,0,1,2.84-2.84h22.67a2.84,2.84,0,0,1,2.84,2.84V253a2.84,2.84,0,0,1-2.84,2.83" transform="translate(0.25 -0.47)"/>
                    <rect className="cls-24" x="170.34" y="227.02" width="28.35" height="28.35" rx="2.83" ry="2.83"/>
                </g>
                <text className="cls-11" transform="translate(173.7 237.88)">66</text>
                <g className="cls-2">
                    <path className="cls-23" d="M252.29,255.84H229.61a2.84,2.84,0,0,1-2.84-2.83V230.33a2.84,2.84,0,0,1,2.84-2.84h22.68a2.84,2.84,0,0,1,2.83,2.84V253a2.83,2.83,0,0,1-2.83,2.83" transform="translate(0.25 -0.47)"/>
                    <rect className="cls-24" x="227.03" y="227.02" width="28.35" height="28.35" rx="2.83" ry="2.83"/>
                </g>
                <text className="cls-14" transform="translate(231.16 237.88)">70</text>
                <g className="cls-2">
                    <path className="cls-9" d="M198.42,372.08v51a2.84,2.84,0,0,0,2.84,2.83h51a2.83,2.83,0,0,0,2.83-2.83v-51a2.83,2.83,0,0,0-2.83-2.83h-51a2.84,2.84,0,0,0-2.84,2.83" transform="translate(0.25 -0.47)"/>
                    <path className="cls-10" d="M201.51,368.78h51a2.83,2.83,0,0,1,2.83,2.83v51a2.83,2.83,0,0,1-2.83,2.83h-51a2.83,2.83,0,0,1-2.83-2.83v-51A2.84,2.84,0,0,1,201.51,368.78Z"/>
                </g>
                <text className="cls-14" transform="translate(202.9 380.23)">6</text>
                <g className="cls-2">
                    <path className="cls-12" d="M308.8,425.94H286.12a2.83,2.83,0,0,1-2.83-2.83v-51a2.84,2.84,0,0,1,2.83-2.84H308.8a2.84,2.84,0,0,1,2.84,2.84v51a2.84,2.84,0,0,1-2.84,2.83" transform="translate(0.25 -0.47)"/>
                    <path className="cls-10" d="M286.38,368.78h22.68a2.83,2.83,0,0,1,2.83,2.83v51a2.83,2.83,0,0,1-2.83,2.83H286.38a2.84,2.84,0,0,1-2.84-2.84v-51A2.84,2.84,0,0,1,286.38,368.78Z"/>
                </g>
                <text className="cls-14" transform="translate(287.66 380.23)">8</text>
                <g className="cls-2">
                    <path className="cls-12" d="M337.15,425.94H314.47a2.83,2.83,0,0,1-2.83-2.83v-51a2.83,2.83,0,0,1,2.83-2.84h22.68a2.84,2.84,0,0,1,2.83,2.84v51a2.83,2.83,0,0,1-2.83,2.83" transform="translate(0.25 -0.47)"/>
                    <rect className="cls-10" x="311.89" y="368.78" width="28.35" height="56.69" rx="2.83" ry="2.83"/>
                </g>
                <text className="cls-14" transform="translate(316.01 380.23)">9</text>
                <g className="cls-2">
                    <path className="cls-12" d="M365.5,425.94H342.82a2.84,2.84,0,0,1-2.84-2.83V372a2.84,2.84,0,0,1,2.84-2.84H365.5a2.84,2.84,0,0,1,2.83,2.84v51.07a2.83,2.83,0,0,1-2.83,2.83" transform="translate(0.25 -0.47)"/>
                    <rect className="cls-10" x="340.24" y="368.73" width="28.35" height="56.74" rx="2.83" ry="2.83"/>
                </g>
                <text className="cls-14" transform="translate(344.36 380.21)">10</text>
                <g className="cls-2">
                    <path className="cls-12" d="M394,425.94H371.34a2.84,2.84,0,0,1-2.84-2.83V372a2.84,2.84,0,0,1,2.84-2.84H394a2.84,2.84,0,0,1,2.83,2.84v51.07a2.83,2.83,0,0,1-2.83,2.83" transform="translate(0.25 -0.47)"/>
                    <rect className="cls-10" x="368.76" y="368.73" width="28.35" height="56.74" rx="2.83" ry="2.83"/>
                </g>
                <text className="cls-14" transform="translate(373.04 380.21)">11</text>
                <g className="cls-2">
                    <path className="cls-9" d="M280.45,425.94H257.78a2.84,2.84,0,0,1-2.84-2.83v-51a2.84,2.84,0,0,1,2.84-2.84h22.67a2.84,2.84,0,0,1,2.84,2.84v51a2.84,2.84,0,0,1-2.84,2.83" transform="translate(0.25 -0.47)"/>
                    <rect className="cls-10" x="255.2" y="368.78" width="28.35" height="56.69" rx="2.83" ry="2.83"/>
                </g>
                <text className="cls-14" transform="translate(259.31 380.23)">7</text>
                <g className="cls-2">
                    <path className="cls-9" d="M0,287V309.7a2.83,2.83,0,0,0,2.83,2.83h51a2.83,2.83,0,0,0,2.83-2.83V287a2.83,2.83,0,0,0-2.83-2.83h-51A2.83,2.83,0,0,0,0,287" transform="translate(0.25 -0.47)"/>
                    <rect className="cls-10" x="0.25" y="283.71" width="56.69" height="28.35" rx="2.83" ry="2.83"/>
                </g>
                <text className="cls-11" transform="translate(4.21 294.57)">45</text>
                <g className="cls-2">
                    <path className="cls-9" d="M0,315.37v22.68a2.83,2.83,0,0,0,2.83,2.83H25.51a2.84,2.84,0,0,0,2.84-2.83V315.37a2.84,2.84,0,0,0-2.84-2.84H2.83A2.84,2.84,0,0,0,0,315.37" transform="translate(0.25 -0.47)"/>
                    <rect className="cls-18" x="0.25" y="312.06" width="28.35" height="28.35" rx="2.83" ry="2.83"/>
                </g>
                <text className="cls-11" transform="translate(4.21 322.92)">46</text>
                <g className="cls-2">
                    <path className="cls-9" d="M28.35,315.37v22.68a2.83,2.83,0,0,0,2.83,2.83H53.86a2.83,2.83,0,0,0,2.83-2.83V315.37a2.84,2.84,0,0,0-2.83-2.84H31.18a2.84,2.84,0,0,0-2.83,2.84" transform="translate(0.25 -0.47)"/>
                    <rect className="cls-18" x="28.6" y="312.06" width="28.35" height="28.35" rx="2.83" ry="2.83"/>
                </g>
                <text className="cls-14" transform="translate(32.54 322.92)">47</text>
                <g className="cls-2">
                    <path className="cls-34" d="M53.85,57.42H31.18a2.84,2.84,0,0,1-2.84-2.84v-51A2.84,2.84,0,0,1,31.18.72H53.85a2.84,2.84,0,0,1,2.84,2.84v51a2.84,2.84,0,0,1-2.84,2.84" transform="translate(0.25 -0.47)"/>
                    <path className="cls-10" d="M31.43.25H54.11a2.84,2.84,0,0,1,2.84,2.84v51a2.83,2.83,0,0,1-2.83,2.83H31.43a2.83,2.83,0,0,1-2.83-2.83v-51A2.83,2.83,0,0,1,31.43.25Z"/>
                </g>
                <text className="cls-14" transform="translate(32.54 12.45)">WC</text>
                <text className="cls-14" transform="translate(32.54 23.25)">ATM</text>
                <g className="cls-2">
                    <path className="cls-9" d="M0,173.64v36.85a2.83,2.83,0,0,0,2.83,2.83h51a2.83,2.83,0,0,0,2.83-2.83V173.64a2.84,2.84,0,0,0-2.83-2.84h-51A2.84,2.84,0,0,0,0,173.64" transform="translate(0.25 -0.47)"/>
                    <rect className="cls-10" x="0.25" y="170.33" width="56.69" height="42.52" rx="2.83" ry="2.83"/>
                </g>
                <text className="cls-14" transform="translate(32.54 181.78)">43</text>
                <g className="cls-2">
                    <path className="cls-12" d="M25.51,255.84H2.84A2.84,2.84,0,0,1,0,253V173.64a2.84,2.84,0,0,1,2.84-2.84H25.51a2.84,2.84,0,0,1,2.84,2.84V253a2.84,2.84,0,0,1-2.84,2.83" transform="translate(0.25 -0.47)"/>
                    <rect className="cls-10" x="0.25" y="170.33" width="28.35" height="85.04" rx="2.83" ry="2.83"/>
                </g>
                <text className="cls-11" transform="translate(4.2 181.78)">42</text>
            </svg>
        );
    }
}

module.exports = TraderMap;
