import React from 'react';
import util from '../../utilities/index.js';


class Footer extends React.Component {

    render() {
        return (
            <footer className='clearfix relative bg-grey h7 white py5 md-pt6 md-pb5'>
                <div className='border-top border-bottom border-light-grey-002'>
                    <div className='clearfix container pt5 line-height-4 caps'>
                        <div className='col col-12 sm-col-6 md-col-3'>
                            <div className='px5 pb5 md-p0 md-px5 md-pb5'>
                                <div className='pb2 md-p0'>
                                    <div className='bold'>
                                        getting here
                                    </div>
                                    <div className='md-show'>—</div>
                                </div>
                                <div
                                    className='rte'
                                    dangerouslySetInnerHTML={{__html: util.try(() => this.props.settings.options.address)}}/>
                            </div>
                        </div>
                        <div className='clearfix border-bottom border-light-grey-002 sm-hide' />
                        <div className='col col-12 sm-col-6 md-col-3'>
                            <div className='px5 py5 sm-p0 sm-px5 sm-pb5'>
                                <div className='pb2 md-p0'>
                                    <div className='bold'>
                                        opening hours
                                    </div>
                                    <div className='md-show'>—</div>
                                </div>
                                <div
                                    className='rte'
                                    dangerouslySetInnerHTML={{__html: util.try(() => this.props.settings.options.opening_times)}}/>
                            </div>
                        </div>
                        <div className='clearfix border-bottom border-light-grey-002 md-hide' />
                        <div className='col col-12 sm-col-6 md-col-3'>
                            <div className='px5 py5 md-p0 md-px5 md-pb5'>
                                <div className='pb2 md-p0'>
                                    <div className='bold'>
                                        information
                                    </div>
                                    <div className='md-show'>—</div>
                                </div>
                                <ul className='list-reset m0'>
                                    {/* Internal Links */}
                                    {util.try(() => this.props.settings.menus.footer.map((item, i) => (
                                        <li key={i}>
                                            <a
                                                href={item.url}
                                                className='underline-accent-green'>
                                                <span>{item.title}</span>
                                            </a>
                                        </li>
                                    )))}
                                </ul>
                            </div>
                        </div>
                        <div className='clearfix border-bottom border-light-grey-002 sm-hide' />
                        <div className='col col-12 sm-col-6 md-col-3'>
                            <div className='px5 py5 md-p0 md-px5 md-pb5'>
                                <div className='pb2 md-p0'>
                                    <div className='bold'>
                                        follow us
                                    </div>
                                    <div className='md-show'>—</div>
                                </div>
                                <ul className='list-reset m0'>
                                    {/* Social Links */}
                                    {util.try(() => this.props.settings.options.social.map((item, i) => (
                                        <li key={i}>
                                            <a
                                                href={item.account[0].link}
                                                className='underline-accent-green'
                                                target='_blank'>
                                                <span>{item.account[0].name}</span>
                                            </a>
                                        </li>
                                    )))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container clearfix">
                    <div className="col-right col-12 sm-col-6 md-col-3 caps">
                        <div className="px5 pt5">
                            <a href="https://codeandink.co" target='_blank' className="underline-accent-green grey-003">
                                <span>Site build by CODE+INK</span>
                            </a>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

module.exports = Footer;
