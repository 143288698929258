/*
 *  Featured homepage primary listing item
 */

import React from 'react';
import util from '../../utilities';
import Image from '../images/image.jsx';

/**
 * @prop {array} item
 * @prop {string} className
 */

class PrimaryListingItem extends React.Component {

    render() {

        let standfirst = util.try(() => this.props.item.acf.standfirst);
        if (util.try(() => this.props.item.acf.secondary_standfirst)) {
            standfirst = this.props.item.acf.secondary_standfirst;
        }

        return (
            <a
                href={util.try(() => util.joinPath(this.props.item.post_type, this.props.item.slug))}
                className={util.try(() => this.props.className) + ' block p2 pb8 bg-white clearfix caslon bg-hover-pastel-yellow'}>
                {(util.try(() => this.props.item.acf.date_overview) && util.try(() => this.props.item.acf.time_info)) &&
                    <div className='mt7'>
                        {util.try(() => this.props.item.acf.date_overview) &&
                            <h3
                                className='red px4 center mb0 mt0'
                                dangerouslySetInnerHTML={{__html: this.props.item.acf.date_overview}} />
                        }
                        {util.try(() => this.props.item.acf.time_info) &&
                            <h3
                                className='red px4 center mb0 mt0'
                                dangerouslySetInnerHTML={{__html: this.props.item.acf.time_info}} />
                        }
                    </div>
                }
                <h3
                    className={'center px4 mb6 md-mb7 ' + util.try(() => this.props.item.acf.time_info ? 'mt0' : 'mt6 md-mt7')}
                    dangerouslySetInnerHTML={{__html:util.try(() => this.props.item.title)}} />
                <div className='mb6 px8 md-px2 clearfix'>
                    <p
                        dangerouslySetInnerHTML={{__html: standfirst}}
                        className='column-count-2 line-height-2 column-gap-1' />
                </div>
                <div className='col-8 mx-auto relative'>
                    <Image
                        mdWidth={50}
                        image={util.try(() => this.props.item.acf.listing_image)}
                        className='col-12'/>
                </div>
                {util.try(() => this.props.item.acf.listing_image.caption) &&
                    <div className='col-6 col-right ag-book grey pl5 md-pt5'>
                        <p className='h8 caps mb0'>Image:</p>
                        <p className='h8 caps mt0' dangerouslySetInnerHTML={{__html:this.props.item.acf.listing_image.caption}} />
                    </div>
                }
            </a>
        );
    }
}

module.exports = PrimaryListingItem;
