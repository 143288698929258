import React from 'react';
import util from '../../utilities';
import moment from 'moment';


class Instagram extends React.Component {

    render() {

        const images = util.try(() => this.props.images.slice(0, 4).map((item, i) => {
            if (i <= 3) {

                const time = moment.unix(item.created_time).fromNow();

                return (
                    <div
                        key={i}
                        className={`col col-6 md-col-3 pb2 ${i > 1 && 'md-show'}`}>
                        <div className='p2'>
                            <div className='clearfix aspect-1-1 overflow-hidden'>
                                <a
                                    target='_blank'
                                    href={item.link}>
                                    <img
                                        className='absolute height-100 centered-xy'
                                        src={item.images.standard_resolution.url} />
                                </a>
                            </div>
                            <div className='py2 pr2 gold h7 caps relative'>

                                <span className="absolute v-align">
                                    <i className='icon-instagram h6 pr2' />
                                </span>
                                <a className='block ml5 line-height-4' href='https://www.instagram.com/oldspitalfieldsmarket'>
                                    {time}
                                </a>

                            </div>
                            <div className='pt2 pr2 gold h7 caps break-word line-clamp-4'>
                                {item.caption.text}
                            </div>
                        </div>
                    </div>
                );
            }
        }));

        return (
            <div className='clearfix relative container'>
                <div className='overflow-hidden bg-white'>
                    {images}
                </div>
            </div>
        );
    }
}

module.exports = Instagram;
