/**
*   Listing filter panel
*/
import React from 'react';
import util from '../../utilities';

class Filter extends React.Component {

    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            active: false
        };
    }

    toggle() {
        this.setState({
            active: !this.state.active
        });
    }

    handleSelect(value) {
        this.toggle(false);
        if (typeof this.props.onUpdate === 'function') {
            if(value === 'all') {
                value = '';
            }
            this.props.onUpdate.call(this, value);
        }
    }

    handleSortSelect(value) {
        this.toggle(false);
        if (typeof this.props.onUpdate === 'function') {
            if(value === 'all') {
                value = '';
            }
            this.props.onSortUpdate.call(this, value);
        }
    }

    render() {

        const activeClass = this.state.active ? 'show' : 'hide';
        const colourClass = !this.state.active || 'bg-pastel-blue';
        let sortBy;
        let selectedLabel;


        let filterOptions = util.try(() => this.props.filters.options.map((option,i) => {

            let activeClass;

            if(this.props.filters.current === option.value) {
                activeClass =  'bg-accent-green';
                selectedLabel = option.label;
            }


            if(this.props.filters.current === null && option.value === 'all') {
                activeClass = 'bg-accent-green';
            }

            return (
                <li
                    key={i}
                    onClick={this.handleSelect.bind(this, option.value)}
                    className={`bg-hover-accent-green caps left mr1 mb1 h7 bg-white ${activeClass}`}>
                    <button
                        className='button p2 caps line-height-1'
                        dangerouslySetInnerHTML={{__html: option.label}} />
                </li>
            );

        }));

        if(this.props.canSortBy) {
            sortBy = (
                <ul className='list-reset left mr4 mb1'>
                    <li className='left mr2 caps h8 py2 line-height-1'>
                        Sort By:
                    </li>
                    <li
                        key={0}
                        tabIndex={0}
                        onClick={this.handleSortSelect.bind(this, 'all')}
                        className={'bg-hover-accent-green left mr1 mb1 h7 bg-white ' + (util.try(() => this.props.orderBy.current) || 'bg-accent-green')}>
                        <button className='button p2 caps line-height-1'>
                            None
                        </button>
                    </li>
                    <li
                        key={1}
                        tabIndex={0}
                        onClick={this.handleSortSelect.bind(this, 'title')}
                        className={'bg-hover-accent-green left mr1 mb1 h7 bg-white ' + (!util.try(() => this.props.orderBy.current) || 'bg-accent-green')}>
                        <button className='button p2 caps line-height-1'>
                            A-Z
                        </button>
                    </li>
                </ul>
            );
        }
        //
        // Combine our filterOptions
        filterOptions = (
            <ul className='list-reset left mb1'>
                <li
                    className='left mr2 caps h8 py2 line-height-1'>Filter By:</li>
                {filterOptions}
            </ul>
        );

        return (
            <div>
                <div className='clearfix container relative'>
                    {this.props.children}
                    <div className={`col absolute right-0 height-100 col-3 ${colourClass}`}>

                        <button className='button height-100 col-12 left-align sm-pl0' onClick={this.toggle}>
                            {/* Mobile filter text */}
                            <div className="md-hide mt2">
                                <span className='border-bottom border-accent-green ml2 sm-ml5'>
                                    Filter
                                </span>
                            </div>
                            {/* Desktop filter text */}
                            <span className='md-show mt2'>
                                <span className='absolute v-align lg-show mt1'>
                                    <i className={'h2 ' + (this.state.active ? 'icon-arrow-up' : 'icon-arrow-down')}></i>
                                </span>
                                <span className='ml5 lg-ml9 block line-height-2'>
                                    <span className="border-bottom border-accent-green lg-border-0">
                                        {this.props.filterText} <br /> {selectedLabel}
                                    </span>
                                </span>
                            </span>

                        </button>

                    </div>
                </div>
                <div className={`bg-pastel-blue clearfix col-12 py6 ${activeClass}`}>
                    <div className='container px5 md-pr9'>
                        {sortBy}
                        {filterOptions}
                    </div>
                </div>
            </div>
        );
    }
}

module.exports = Filter;
