/*
 * Map
 */

import React from 'react';
import util from '../../utilities';


/**
 * Class representing a Map
 * @extends React.Component
 * @prop {object} location
 * @prop {number} zoom
 * @prop {string} width
 * @prop {number} height
 * @prop {string} icon
 */
class MapComponent extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        if (typeof google === 'undefined' || !google.maps) {
            let script = document.createElement('script');
            script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyBU3_EtQkEecgU70DOCwB9fazwdsHxRmgc&callback=initMap';
            document.head.appendChild(script);
            window.initMap = this.createMap.bind(this)
        } else {
            this.createMap();
        }
    }

    createMap() {
        if (!this.map) {
            const center = util.try(() => new google.maps.LatLng(this.props.location.lat, this.props.location.lng))

            this.map = new google.maps.Map(this.refs.map, {
                zoom: this.props.zoom,
                center: center,
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                mapTypeControl: false,
                scrollwheel: false,
                streetViewControl: false,
                zoomControl: true,
                styles: this.props.style
            });

            const calculateCenter = () => this.center = this.map.getCenter()

            google.maps.event
                .addDomListener(this.refs.map, 'idle', calculateCenter());

            google.maps.event
                .addDomListener(window, 'resize', this.map.setCenter(this.center))

            if (this.props.location) {
                this.marker = new google.maps.Marker({
                    position: center,
                    map: this.map,
                    icon: {
                        url: this.props.icon,
                        size: new google.maps.Size(60, 60),
                        scaledSize: new google.maps.Size(30, 30),
                        anchor: new google.maps.Point(15, 15)
                    }
                });
            }
        }
    };

    render() {
        return (
            <div
                ref='map'
                className={this.props.className}
                style={this.props.style} />
        );
    }

}

MapComponent.defaultProps = {
    location: {
        lat: 51.5196978,
        lng: -0.0758808
    },
    zoom: 16,
    width: '100%',
    height: '100%',
    icon: '/images/map-pointer.png',
    style: [
         {
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#f5f5f5"
                }
            ]
        }, {
            "elementType": "labels.icon",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        }, {
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#616161"
                }
            ]
        }, {
            "elementType": "labels.text.stroke",
            "stylers": [
                {
                    "color": "#f5f5f5"
                }
            ]
        }, {
            "featureType": "administrative.land_parcel",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#bdbdbd"
                }
            ]
        }, {
            "featureType": "poi",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#eeeeee"
                }
            ]
        }, {
            "featureType": "poi",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#757575"
                }
            ]
        }, {
            "featureType": "poi.park",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#e5e5e5"
                }
            ]
        }, {
            "featureType": "poi.park",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#9e9e9e"
                }
            ]
        }, {
            "featureType": "road",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#ffffff"
                }
            ]
        }, {
            "featureType": "road.arterial",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#757575"
                }
            ]
        }, {
            "featureType": "road.highway",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#dadada"
                }
            ]
        }, {
            "featureType": "road.highway",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#616161"
                }
            ]
        }, {
            "featureType": "road.local",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#9e9e9e"
                }
            ]
        }, {
            "featureType": "transit.line",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#e5e5e5"
                }
            ]
        }, {
            "featureType": "transit.station",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#eeeeee"
                }
            ]
        }, {
            "featureType": "water",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#c9c9c9"
                }
            ]
        }, {
            "featureType": "water",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#9e9e9e"
                }
            ]
        }
    ]
};

module.exports = MapComponent;
