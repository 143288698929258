/**
*   Grid item for listing
*/
import React from 'react';
import util from '../../../utilities';
import Image from '../../images/image.jsx';
import LoadedImage from '../../images/loaded-image.jsx';


class FeatureBusinessItem extends React.Component {

    render () {

        const item = this.props.item;

        const title = item.title.rendered ? item.title.rendered : item.title;

        let url;
        if (util.try(() => this.props.baseUrl)) {
            url = this.props.baseUrl + '/' + item.slug;
        } else {
            url = util.joinPath(item.type, item.slug);
        }

        return (
            <div className='border-bottom border-light-grey-001 md-sticky col col-12 md-col-6 top-0'>
                <a className='block bg-hover-pastel-yellow clearfix pb6' href={url}>
                    {util.try(() => this.props.typeTitle) && (
                        <div className='px5 pt2 pt2'>
                            <span
                                className='h7 caps'
                                dangerouslySetInnerHTML={{__html: this.props.typeTitle}} />
                        </div>
                    )}
                    {util.try(() => item.acf.listing_image) && (
                        <div className="p2">
                            <LoadedImage
                                width={100}
                                mdWidth={50}
                                image={item.acf.listing_image} />
                        </div>
                    )}
                    <div className='clearfix pb6'>
                        <h4 className='caslon px5'>
                            <span dangerouslySetInnerHTML={{__html: title}} />
                        </h4>
                        <div className="col col-12 sm-col-6">
                            <p
                                dangerouslySetInnerHTML={{__html: item.acf.standfirst}}
                                className='px5 line-height-2 caslon' />
                        </div>
                        <div className="col col-12 sm-col-6">
                            {util.try(() => item.acf.secondary_standfirst) && (
                                <p
                                    dangerouslySetInnerHTML={{__html: item.acf.secondary_standfirst}}
                                    className='px5 line-height-2 caslon' />
                            )}
                        </div>
                    </div>

                </a>
            </div>
        );
    }
}

module.exports = FeatureBusinessItem;
