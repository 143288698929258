/*
 * Journal listing page
 */

import React from 'react';
import util from '../../utilities';
import Header from '../../partials/global/header.jsx';
import Footer from '../../partials/global/footer.jsx';
import Page from '../../partials/layout/page.jsx';
import Image from '../../partials/images/image.jsx';
import ListingComponent from '../../partials/listing/events/index.jsx';

class EventsPage extends React.Component {

    render() {

        return (
            <Page>
                <Header settings={util.try(() => this.props.settings)} />
                <ListingComponent
                    {...this.props} />
                <Footer settings={this.props.settings} />
            </Page>
        );
    }
}

module.exports = EventsPage;
