import React from 'react'
import Image from '../images/image.jsx'

export default class Gallery extends React.Component {

    constructor() {
        super();
        this.toggle = this.toggle.bind(this);
        this.state = {
            open: false
        };
    }

    toggle() {
        this.setState({open: !this.state.open});
    }

    render() {

        const ImageComponent = props => (
            <div>
                <Image image={props.image} />
                <div className='col col-12 sm-col-6 md-col-3 caps h7 blue py4'>
                    {props.image.caption}
                </div>
            </div>
        );

        // map provided images excluding first
        const collapsedImages = this.props.images.map((image, i, self) => i > 0 && (
            <ImageComponent
                key={i}
                image={image} />
        ));

        const toggle = this.state.open
            ? ''
            : 'hide';

        const message = (
            <div>
                <div>
                    {this.state.open
                        ? `${this.props.images.length} of ${this.props.images.length}`
                        : `1 of ${this.props.images.length}`}
                </div>
                <div>
                    {this.state.open
                        ? 'Show fewer'
                        : 'Show all'}
                </div>
            </div>
        );

        const arrow = this.state.open
            ? 'icon-arrow-up'
            : 'icon-arrow-down';

        return (
            <div className='bg-pastel-yellow'>
                <div className='clearfix container px5 md-px9'>
                    <ImageComponent
                        key={0}
                        image={this.props.images[0]} />
                    <div className={toggle}>
                        {collapsedImages}
                    </div>
                    <div
                        className='col-right col-12 sm-col-6 bg-pastel-white p4 cursor-pointer'
                        onClick={this.toggle}>
                        <i className={`${arrow} inline-block`} />
                        <div
                            style={{verticalAlign: 'sub'}}
                            className='pl4 caps h7 blue inline-block'>
                            {message}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
