/*
 *  Featured homepage listing item
 */

import React from 'react';
import util from '../../utilities';
import Image from '../images/image.jsx';

/**
 * @prop {array} item
 * @prop {string} className
 */

class ListingItem extends React.Component {

    render() {

        let standfirst = util.try(() => this.props.item.acf.standfirst);
        if (util.try(() => this.props.item.acf.secondary_standfirst && this.props.isPrimary)) {
            standfirst = standfirst + ' ' + this.props.item.acf.secondary_standfirst;
        }

        if (util.try(() => this.props.item.type) === 'event') {
            return (
                <a
                    href={util.try(() => util.joinPath(this.props.item.post_type, this.props.item.slug))}
                    className={'block p2 pb5 bg-white bg-hover-pastel-blue caslon ' + util.try(() => this.props.className)}>
                    <Image
                        smWidth={50}
                        mdWidth={25}
                        image={util.try(() => this.props.item.acf.listing_image)}
                        className='inline-block col-12'/>
                    {util.try(() => this.props.item.acf.time_info) &&
                            <div className='px2 pt5'>
                                {util.try(() => this.props.item.acf.date_overview) &&
                                    <div
                                        className='h4 m0 red'
                                        dangerouslySetInnerHTML={{__html: this.props.item.acf.date_overview}} />
                                }
                                <h4
                                    className='red m0'
                                    dangerouslySetInnerHTML={{__html: this.props.item.acf.time_info}} />
                                <div>—</div>
                            </div>
                        }
                        <div className='px2'>
                            <h4
                                className={util.try(() => this.props.item.acf.time_info ? 'mt0' : '')}
                                dangerouslySetInnerHTML={{__html: util.try(() => this.props.item.title)}} />
                            <p
                                className='line-height-2'
                                dangerouslySetInnerHTML={{__html: standfirst}} />
                        </div>
                </a>
            );
        } else {
            return (
                <a
                    href={util.try(() => util.joinPath(this.props.item.post_type, this.props.item.slug))}
                    className={'block p2 pb5 bg-white bg-hover-pastel-blue caslon ' + util.try(() => this.props.className)}>
                    <Image
                        smWidth={50}
                        mdWidth={25}
                        image={util.try(() => this.props.item.acf.listing_image)}
                        className='inline-block col-12'/>
                    <div className='px2'>
                        <h4 dangerouslySetInnerHTML={{__html: util.try(() => this.props.item.title)}} />
                        <p
                            className='line-height-2'
                            dangerouslySetInnerHTML={{__html: util.try(() => standfirst)}} />
                    </div>
                </a>
            );
        }
    }
}

module.exports = ListingItem;
