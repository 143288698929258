/*
 * Journal
 */

import React from 'react';
import util from '../../utilities';
import Page from '../../partials/layout/page.jsx';
import Header from '../../partials/global/header.jsx';
import Footer from '../../partials/global/footer.jsx';
import ModuleSwitcher from '../../partials/journal/module-switcher.jsx';
import RelatedContent from '../../partials/layout/related-content.jsx';
import Tags from '../../partials/layout/tag-cloud.jsx';
import Standfirst from '../../partials/layout/standfirst.jsx';

class Journal extends React.Component {

    render() {
        return (
            <Page>
                <Header settings={util.try(() => this.props.settings)} />

                <Standfirst
                    type='Journal'
                    typeLink='/journal'
                    paths={this.props.settings.urls}
                    title={this.props.page.title.rendered}
                    standfirst={this.props.page.acf.standfirst} />

                <ModuleSwitcher
                    content={util.try(() => this.props.page.acf.content_modules)} />

                <div className='border-top border-light-grey-001' />

                <Tags
                    tags={util.try(() => this.props.page.tag)} />

                {util.try(() => this.props.page.acf.related_content) &&
                    <RelatedContent
                        title='Related Content'
                        content={util.try(() => this.props.page.acf.related_content)} />
                }

                <Footer settings={this.props.settings} />
            </Page>
        );
    }
}

module.exports = Journal;
