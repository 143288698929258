/*
 * Journal listing page
 */

import React from 'react';
import util from '../utilities';
import Header from '../partials/global/header.jsx';
import Footer from '../partials/global/footer.jsx';
import Page from '../partials/layout/page.jsx';
import Image from '../partials/images/image.jsx';
import Pagination from '../partials/listing/shared/pagination.jsx';

class SearchPage extends React.Component {

    navigate(page) {
        window.location = `/search?term=${this.props.term}&page=${page}`;
    }

    render() {

        const getType = (type) => {
            switch (type) {
            case 'article':
                return 'journal';
            case 'food_drink':
                return 'food & drink';
            case 'shops_stalls':
                return 'shops & stalls';
            case 'event':
                return 'events';
            }
        };

        const items = util.try(() => this.props.posts.map((item, i) => (
            <div
                key={i}
                className="border-bottom border-light-grey-001 py5">
                <div className='px5 container'>
                    <div className='caps h7'>
                        <span className="border-bottom border-accent-green">
                            {getType(item.type)}
                        </span>
                    </div>
                    <div className='py2'>
                        <a
                            href={item.link}
                            className='caslon h4'
                            dangerouslySetInnerHTML={{__html: item.title.rendered}} />
                    </div>
                    <p className='m0 caslon col-8'>
                        {util.try(() => item.acf.standfirst)}
                    </p>
                </div>
            </div>
        )));

        // 20 results found for x
        const searchString = `${this.props.total} result${this.props.total === 1 ? '' : 's'} found for ${this.props.term}`;

        return (
            <Page>
                <Header
                    search={this.props.term}
                    settings={util.try(() => this.props.settings)} />

                <div className='border-bottom border-light-grey-001'>
                    <h1 className='container caslon h2 px5 m0 pt7 pb5'>
                        {searchString}
                    </h1>
                </div>

                <div className='clearfix relative'>
                    {items}
                </div>

                {this.props.totalPages > 1 && (
                    <Pagination
                        navigate={this.navigate.bind(this)}
                        current={this.props.currentPage}
                        total={this.props.totalPages} />
                )}

                <Footer settings={this.props.settings} />
            </Page>
        );
    }
}

module.exports = SearchPage;
