/*
 * Business
 */

import React from 'react';
import util from '../../utilities';
import Page from '../../partials/layout/page.jsx';
import Header from '../../partials/global/header.jsx';
import Footer from '../../partials/global/footer.jsx';
import Image from '../../partials/images/image.jsx';
import ReadMore from '../../partials/layout/read-more.jsx';
import TagCloud from '../../partials/layout/tag-cloud.jsx';
import Standfirst from '../../partials/layout/standfirst.jsx';
import Description from '../../partials/shared/description.jsx';
import Gallery from '../../partials/shared/gallery.jsx';
import FreeText from '../../partials/shared/free-text.jsx';
import Menu from '../../partials/shared/menu.jsx';
import RelatedContent from '../../partials/layout/related-content.jsx';



class Business extends React.Component {

    render() {

        let articleType;
        let articleTypeLink;
        let articleTypeName;


        // Get type and taxonomy type of post from embedded
        if (util.try(() => this.props.page._embedded['wp:term'])) {

            const articleType = util.try(() => {
                let articleType = this.props.page._embedded['wp:term'][0].filter((term) => {
                    if(term.taxonomy === 'food-drink-type' || term.taxonomy === 'shop-stall-type') {
                        return true;
                    }
                });
                return articleType.pop();
            });
            const articleTypeName = util.try(() => articleType.name);
            const articleTypeId = util.try(() => articleType.id) || '';
            if (util.try(() => this.props.page.type === 'shops_stalls')) {
                articleTypeLink = '/shops-and-stalls?type=' + articleTypeId;
            } else {
                articleTypeLink = '/food-and-drink?type=' + articleTypeId;
            }

        }

        return (
            <Page>
                <Header settings={util.try(() => this.props.settings)} />

                <Standfirst
                    type={articleTypeName}
                    typeLink={articleTypeLink}
                    title={util.try(() => this.props.page.title.rendered)}
                    paths={util.try(() => this.props.settings.urls)}
                    standfirst={util.try(() => this.props.page.acf.standfirst)} />

                <Description
                    image={util.try(() => this.props.page.acf.image)}
                    content={util.try(() => this.props.page.acf.content)}
                    hidden={util.try(() => this.props.page.acf.hidden_content)} />

                {(util.try(() => this.props.page.acf.column_1) || util.try(() => this.props.page.acf.column_2)) &&
                    <FreeText
                        column1={util.try(() => this.props.page.acf.column_1)}
                        column2={util.try(() => this.props.page.acf.column_2)} />
                }

                {util.try(() => this.props.page.acf.menu_download) &&
                    <Menu
                        title={util.try(() => this.props.page.acf.menu_download[0].file_name)}
                        link={util.try(() => this.props.page.acf.menu_download[0].file.url)} />
                }

                {util.isArray(this.props.page.acf.gallery) &&
                    <Gallery
                        images={this.props.page.acf.gallery} />
                }

                <div className='border-top border-light-grey-001' />

                {util.isArray(this.props.page.tag) &&
                    <TagCloud
                        tags={this.props.page.tag} />
                }

                {util.try(() => this.props.page.tag.length === 0) &&
                    <div className='bg-pastel-blue col-12 py7 md-py9' />
                }

                {util.try(() => this.props.page.acf.related) &&
                    <RelatedContent
                        title='Related Content'
                        content={util.try(() => this.props.page.acf.related)} />
                }

                <Footer settings={this.props.settings} />
            </Page>
        );
    }
}

module.exports = Business;
