import React from 'react';
import Image from '../images/image.jsx';
import ReadMore from '../layout/read-more.jsx';


export default class Description extends React.Component {
    render() {
        return (
            <div className='bg-pastel-blue blue clearfix'>
                <div className='clearfix container bg-pastel-white'>
                    <div className='col col-12 sm-col-6'>
                        <div className='clearfix aspect-1-1 overflow-hidden'>
                            <Image
                                className='absolute col-auto height-100 centered-xy'
                                image={this.props.image} />
                        </div>
                    </div>
                    <div className='col col-12 sm-col-6 caslon h4 md-h9 lg-h4 py7 md-py9'>
                        <div className='px5 lg-px9'>
                            <ReadMore
                                content={this.props.content}
                                hidden={this.props.hidden} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
