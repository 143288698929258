/**
*   Grid item for listing
*/
import React from 'react';
import util from '../../../utilities';
import Image from '../../images/image.jsx';
import LoadedImage from '../../images/loaded-image.jsx';

class Item extends React.Component {

    render () {

        const item = this.props.item;
        const baseUrl = this.props.baseUrl;
        const title = item.title.rendered ? item.title.rendered : item.title;

        let url;
        if (util.try(() => this.props.baseUrl)) {
            url = this.props.baseUrl + '/' + item.slug;
        } else {
            url = util.joinPath(item.type, item.slug);
        }

        return (
            <div className='border-bottom border-light-grey-001'>
                <a className='block clearfix bg-hover-pastel-blue' href={url}>
                    <div className='pb6'>
                        {util.try(() => this.props.typeTitle) &&
                            <div className='px5 pt2 pt2'>
                                <span
                                    className='h7 caps'
                                    dangerouslySetInnerHTML={{__html:this.props.typeTitle}} />
                            </div>
                        }
                        <div className="p2">
                            <LoadedImage
                                width={100}
                                smWidth={50}
                                mdWidth={25}
                                image={util.try(() => item.acf.listing_image)} />
                        </div>
                        <h4
                            dangerouslySetInnerHTML={{__html: title}}
                            className={`caslon px5 ${util.try(() => this.props.isJournal) ? 'letter-spacing-1' : ''}`} />
                        <div>
                            <p
                                dangerouslySetInnerHTML={{__html: item.acf.standfirst}}
                                className='caslon px5 line-height-2' />
                        </div>
                    </div>
                </a>
            </div>
        );
    }
}

module.exports = Item;
