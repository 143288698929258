import React from 'react';
import util from '../../utilities';
import BackgroundImage from '../images/background.jsx';
import HomepageCTA from './call-to-action.jsx';

/**
 * @prop {object} content
 */


class FullWidthCTA extends React.Component {


    render() {

        const src = util.try(() => this.props.content.background_image.sizes['custom-extra-large']) ||
            util.try(() => this.props.content.background_image.url);

        return (

            <div className='relative'>

                <BackgroundImage
                    src={src}
                    className='h100' />

                <div className='bg-black absolute top-0 left-0 right-0 bottom-0 opacity-5' />

                <div className='clearfix'>
                    <div className='container absolute mb9 top-0 right-0 left-0 z1 white clearfix'>
                        <div className='mb9'>
                            <div className='col-10 sm-col-6'>
                                <div className='mb5 md-mb9'>
                                    <HomepageCTA
                                        link={util.try(() => this.props.content.call_to_action_link)}
                                        callToAction={util.try(() => this.props.content.call_to_action_text)} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        );
    }
}

module.exports = FullWidthCTA;
