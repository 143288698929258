/*
 * App
 */

import React from 'react';
import TrackingScripts from '../partials/global/tracking-scripts.jsx';
import CookieBanner from '../partials/global/cookie-banner.jsx';
import pages from '../pages';
import util from '../utilities';


/**
 * Class representing the application
 * @extends React.component
 */
class App extends React.Component {

    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this.state = {
            allowsCookies: util.try(() => typeof this.props.settings.allowsCookies === 'boolean') ?
                this.props.settings.allowsCookies : undefined
        };
    }

    handleClick(val) {
        util.cookies.setItem('osm_allows_cookies', val);
        this.setState({allowsCookies: val}, () => {
            if (this.state.allowsCookies) {
                window.location.reload(false);
            }
        });
    }

    render() {

        let Content;

        if (this.props.pageComponent &&
            typeof(pages[this.props.pageComponent]) !== 'undefined') {
            const Component = pages[this.props.pageComponent];
            Content = (
                <Component {...this.props} />
            );
        }

        return (
            <React.Fragment>
                <CookieBanner
                    onHandleClick={this.handleClick}
                    allowsCookies={this.state.allowsCookies} />
                <main className='height-100'>
                    {Content}
                </main>
                <TrackingScripts
                    allowsCookies={this.state.allowsCookies} />
            </React.Fragment>
        );
    }

};

module.exports = App;
