/*
 * Listing Container
 */

import React from 'react';
import util from '../../../utilities';
import Container from '../base/infinite-container.jsx';
import Listing from './listing.jsx';
import Filters from '../../ui/filter.jsx';


/**
 * Class representing the Listing Container
 * @extends React.component
 */

class ListingContainer extends Container {

    constructor (props) {
        super(props);

        this.handleLoad = this.handleLoad.bind(this);
        this.toggleFilters = this.toggleFilters.bind(this);
        this.state.totalPages = this.props.totalPages || 1;
        this.state.filtersActive = false;
        this.state.featuredItems = util.try(() => this.props.content.acf.featured);
        this.state.randSeed = this.props.randSeed;
    }

    handleUpdate(key, value) {
        if (typeof this.handleFilter === 'function') {
            if(value === 'all') {
                value = '';
            }
            this.handleFilter(key, value);
        }
    }

    toggleFilters() {
        this.setState({
            filtersActive: this.state.filtersActive ? false : true
        });
    }

    render () {

        let jsonEndpoint = 'shops-stalls-json';
        if(util.try(() => this.props.content.template) === 'food-drink-template.php') {
            jsonEndpoint = '/food-and-drink-json';
        }


        return (
            <div>
                <Filters
                    onUpdate={this.handleUpdate.bind(this, 'type')}
                    canSortBy={true}
                    onSortUpdate={this.handleUpdate.bind(this, 'orderby')}
                    filters={util.try(() => this.props.filters.type)}
                    orderBy={util.try(() => this.props.filters.orderby)}
                    filterText='Sort &amp; Filter'>

                    <div className='col col-12 sm-col-9'>
                        <h1 className='caslon h2 px5 m0 pt7 pb5'>
                            <span dangerouslySetInnerHTML={{__html: util.try(() => this.props.title)}}></span>
                        </h1>
                    </div>

                </Filters>
                {/* Listing */}
                <Listing
                    title={util.try(() => this.props.title)}
                    path={jsonEndpoint}
                    baseUrl={this.props.baseUrl}
                    query={{
                        type: util.try(() => this.state.filters.type.current),
                        orderby: util.try(() => this.state.filters.orderby.current),
                        randSeed: this.state.randSeed,
                        pages: this.state.pages
                    }}
                    morePages={this.state.morePages}
                    items={this.props.items}
                    featuredItems={this.state.featuredItems}
                    onLoadStart={this.handleLoadStart}
                    onLoad={this.handleLoad}
                    nextPage={this.props.nextPage}
                    page={this.state.page} />
            </div>

        );
    }
}

module.exports = ListingContainer;
