import React from 'react';
import Header from '../partials/global/header.jsx';
import Footer from '../partials/global/footer.jsx';
import Page from '../partials/layout/page.jsx';
import Image from '../partials/images/image.jsx';
import Map from '../partials/ui/map.jsx';
import util from '../utilities';
import RelatedContent from '../partials/layout/related-content.jsx';

class Visitors extends React.Component {


    render() {

        const backgroundImageUrl = util.try(() => this.props.page.acf.hero_image.sizes['custom-extra-large']) ||
            util.try(() => this.props.page.acf.hero_image.url);

        const backgroundImage = util.try(() => this.props.page.acf.hero_image);

        return (
            <Page>
                <Header settings={util.try(() => this.props.settings)} />
                <div className='md-sticky top-0'>
                    <Image
                        aspect='16-9'
                        className='col-12 md-hide'
                        image={backgroundImage} />
                    <div
                        className='md-show md-h100 md-absolute top-0 bottom-0 left-0 right-0 zn1 transition-opacity big-bg'
                        style={{
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            backgroundImage: `url(${backgroundImageUrl})`
                        }} />
                </div>
                <div
                    className='relative container md-mt-38' style={{WebkitTransform: 'translate3d(0,0,0)'}}>
                    <div className='clearfix bg-white pb5 md-py5 md-pt9'>
                        <h1 className='m0 caslon sm-hide clearfix h1 px5 pb3 pt5 sm-hide border-bottom border-light-grey-001'>
                            Visitor Information
                        </h1>
                        <div className='col col-12 md-col-6 px5 py7 md-pt0 lg-px9 md-pr5'>
                            <h2 className='m0 limit-line-width letter-spacing-0'>
                                {this.props.page.acf.standfirst}
                            </h2>
                        </div>
                        <div className='caslon'>
                            <div className='col col-12 sm-col-6 md-col-3'>
                                <div className='px5'>
                                    <h4 className='m0 pb4'>
                                        Opening Times
                                    </h4>
                                    <div className='md-show'>—</div>
                                    <div
                                        className='rte'
                                        dangerouslySetInnerHTML={{__html: util.try(() => this.props.page.acf.opening_times)}} />
                                </div>
                            </div>
                            <div className='col col-12 sm-hide border-top border-light-grey-001 mt5 mb5' />
                            <div className='col col-12 sm-col-6 md-col-3'>
                                <div className='px5'>
                                    <h4 className='m0 pb4'>
                                        Getting Here
                                    </h4>
                                    <div className='md-show'>—</div>
                                    <div
                                        className='rte'
                                        dangerouslySetInnerHTML={{__html: util.try(() => this.props.page.acf.getting_here)}} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <Map className='aspect-1-1 sm-aspect-2-1' />
                </div>
                <div className='relative container clearfix' style={{WebkitTransform: 'translate3d(0,0,0)'}}>
                    <h2 className='caslon md-white m0 pb3 pt5 px5 md-pt6 lg-px9'>
                        Plan your visit
                    </h2>
                </div>
                <div className='md-border-top border-light-grey-001'>
                    <div className='relative container clearfix bg-white' style={{WebkitTransform: 'translate3d(0,0,0)'}}>
                        <RelatedContent
                            content={this.props.page.acf.plan_your_visit}/>
                    </div>
                </div>
                <div className='relative' style={{WebkitTransform: 'translate3d(0,0,0)'}} >
                    <Footer settings={this.props.settings} />
                </div>
            </Page>
        );
    }
}

module.exports = Visitors;
