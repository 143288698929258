/*
 * Page component for generic pages
 */

import React from 'react';
import util from '../utilities';
import Header from '../partials/global/header.jsx';
import Footer from '../partials/global/footer.jsx';
import Page from '../partials/layout/page.jsx';
import Standfirst from '../partials/layout/standfirst.jsx';
import Image from '../partials/images/image.jsx';
import BackgroundImage from '../partials/images/background.jsx';
import Description from '../partials/shared/description.jsx';
import RelatedContent from '../partials/layout/related-content.jsx';
import NeighbourhoodCardForm from '../partials/NeighbourhoodCardForm';


class GenericPage extends React.Component {

    render() {
        return (
            <Page>
                <Header settings={util.try(() => this.props.settings)} />

                <Standfirst
                    title={this.props.page.title.rendered}
                    standfirst={this.props.page.acf.standfirst} />


                {util.try(() => this.props.page.acf.image) && util.try(() => this.props.page.acf.content) &&
                    <div className='bg-pastel-blue'>
                        <Description
                            image={this.props.page.acf.image}
                            content={this.props.page.acf.content}
                            hidden={this.props.page.acf.hidden_content} />

                        {util.try(() => this.props.page.acf.related) &&
                            <div className='mt7 md-mt8'>
                                <div className='bg-white'>
                                    <RelatedContent
                                        title='Related Content'
                                        content={util.try(() => this.props.page.acf.related)} />
                                </div>
                            </div>
                        }
                    </div>
                }
                {this.props.settings.urls.path == '/neighbourhood-card' && 
                    <NeighbourhoodCardForm />
                }

                <Footer settings={this.props.settings} />
            </Page>
        );
    }
};

module.exports = GenericPage;
