/*
 * Related Content
 */

import React from 'react';
import util from '../../utilities';
import Image from '../images/image.jsx';
import Col from '../layout/columns.jsx';


/**
 * @prop {string} title
 * @prop {array} content
 */

class RelatedContent extends React.Component {
    render() {

        if (!util.try(() => this.props.content)) {
            return <span/>;
        }

        const blocks = this.props.content.map((block, i) => {

            let category;
            let url;
            let listingUrl;
            switch (block.type) {
            case 'food_drink':
                category = 'Food & Drink';
                url = `/food-and-drink/${block.slug}`;
                listingUrl = '/food-and-drink';
                break;
            case 'event':
                category = 'Event';
                url = `/events/${block.slug}`;
                listingUrl = '/events';
                break;
            case 'article':
                category = 'Article';
                url = `/journal/${block.slug}`;
                listingUrl = '/journal';
                break;
            case 'shops_stalls':
                category = 'Shops & Stall';
                url = `/shops-and-stalls/${block.slug}`;
                listingUrl = '/shops-and-stalls';
                break;
            default:
                category = 'Page';
                url = `/${block.slug}`;
                break;
            }

            const border =  'border-bottom border-light-grey-001 md-border-0';

            let categoryTitle = '';
            if (listingUrl) {
                categoryTitle = (
                    <div className='px5 py4'>
                        <a
                            href={listingUrl}
                            className='caps h7 block'
                            dangerouslySetInnerHTML={{__html: category}} />
                    </div>
                );
            } else {
                categoryTitle = (
                    <div
                        className='caps h7 px5 py4'
                        dangerouslySetInnerHTML={{__html: category}} />
                );
            }

            return (
                <div
                    key={i}
                    className={border}>
                    {categoryTitle}
                    <a href={url}>
                        <Image
                            className='px2 inline-block'
                            image={block.acf.listing_image} />
                    </a>
                    <div className='px5'>
                        <a href={url}>
                            <h4
                                className='h4 caslon'
                                dangerouslySetInnerHTML={{__html: block.title}} />
                        </a>
                        {block.acf.standfirst &&
                            <p
                                className='caslon pb6 sm-pb7 md-pb8 mb0'
                                dangerouslySetInnerHTML={{__html: block.acf.standfirst}} />
                        }
                    </div>
                </div>
            );

        });

        const title = util.try(() => this.props.title) && (
            <div className='container clearfix'>
                <h3
                    className='h3 caslon px5 mt5 mb4 sm-mt6 sm-mb5 lg-px9'
                    dangerouslySetInnerHTML={{__html: this.props.title}} />
            </div>
        );

        return (
            <div>
                {title}
                <div className='border-bottom border-light-grey-001 col-12' />
                <div className='container clearfix sm-hide'>
                    {blocks}
                </div>
                <div className='container clearfix sm-show md-hide'>
                    <Col items={blocks} count={2} />
                </div>
                <div className='container clearfix md-show'>
                    <Col items={blocks} count={4} />
                </div>
            </div>
        );

    }
}

module.exports = RelatedContent;
