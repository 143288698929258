/*
* Header
*/

import React, {Component, Fragment} from 'react';
import util from '../../utilities';

class Header extends Component {

    constructor(props) {
        super(props);

        this.toggleMenu = this.toggleMenu.bind(this);
        this.toggleSearch = this.toggleSearch.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.setYPosition = this.setYPosition.bind(this);
        this.submitSearch = this.submitSearch.bind(this);
        this.scrollTo = this.scrollTo.bind(this);

        this.links = this.buildLinks();

        this.state = {
            active: false,
            didScroll: false,
            navBar: null,
            previousScrollPosition: 0,
            scrollUpPosition: 0,
            scrollingUp: false,
            scrollingThreshold: 100,
            slideNavBar: false,
            showNavBar: false,
            searchActive: !!(props.search),
            searchTerm: props.search || ''
        };
    }

    /**
     *  Build header links from stall taxonomies and links setup in wordpress
     */
    buildLinks() {

        if (!util.try(() => this.props.settings)) {
            return {};
        }

        let links = {};

        Object.keys(this.props.settings.taxonomies).forEach((key) => {
            const taxonomy = this.props.settings.taxonomies[key];

            const all = [{
                label: `All ${key}`,
                value: taxonomy.route
            }];
            const filtered = taxonomy.items.map((link) => ({
                label: link.name,
                value: `/${taxonomy.route}?type=${link.id}`
            }));

            links[key] = [...all, ...filtered];
        });

        this.props.settings.menus.header.forEach((col) => {
            const options = col.children.map((link) => ({
                label: link.title,
                value: link.url
            }));
            links[col.title] = options;
        });

        return links;
    }

    toggleMenu() {
        if (this.refs.navBar.getBoundingClientRect().top > 0) {
            this.scrollTo(200, this.refs.navBar);
        } else {
            this.setState({
                active: !this.state.active
            });
        }
        if (!this.state.active) {
            this.setState({
                slideNavBar: true,
                showNavBar: true
            });
        }
        document.getElementsByTagName('body')[0].classList.toggle('overflow-hidden');
    }

    toggleSearch() {
        if (this.state.searchTerm.length < 1) {
            this.setState({searchActive: !this.state.searchActive}, () => {
                if (this.state.searchActive) {
                    this.refs.desktopSearchBox.focus();
                } else {
                    this.refs.desktopSearchBox.blur();
                }
            });
        } else {
            this.submitSearch();
        }
    }

    submitSearch(e) {
        e && e.preventDefault();
        window.location = `/search?term=${this.state.searchTerm}`;
    }

    scrollTo(duration, targetElement) {

        const start = window.pageYOffset;
        const change = targetElement.getBoundingClientRect().top +1;
        let currentTime = 0;
        const increment = 20;

        duration = (typeof(duration) === 'undefined') ? 500 : duration;

        const easeIn = (t, b, c, d) => {
            const tc = (t /= d) * t * t;
            return b + c * (tc);
        };

        const animateScroll = () => {
            currentTime += increment;
            const val = easeIn(currentTime, start, change, duration);
            window.scrollTo(0, val);

            if (currentTime < duration) {
                window.requestAnimationFrame(animateScroll);
            } else {
                this.setState({
                    active: !this.state.active
                });
            }
        };
        animateScroll();
    }

    componentDidMount() {

        this.setYPosition('navBar');

        window.addEventListener('scroll', (() => {
            this.setState({
                didScroll: true
            });
        }));

        window.addEventListener('resize', (() => {
            this.setYPosition('navBar');
        }));

        setInterval(() => {
            if (this.state.didScroll) {
                this.handleScroll();
                this.setState({
                    didScroll: false
                });
            }
        }, 150);
    }

    setYPosition(ref) {
        const el = this.refs[ref].getBoundingClientRect().bottom + window.pageYOffset;
        let obj = {};
        obj[ref] = el;
        this.setState(obj);
    }

    handleScroll() {

        if (window.pageYOffset > this.state.navBar) {
            if (window.pageYOffset > this.state.previousScrollPosition) {
                this.setState({
                    slideNavBar: false,
                    scrollingUp: false,
                    scrollUpPosition: 0
                });
            } else if (window.pageYOffset < this.state.scrollUpPosition - this.state.scrollingThreshold) {
                this.setState({
                    slideNavBar: true
                });
            } else {
                if (!this.state.scrollingUp) {
                    this.setState({
                        scrollingUp: true,
                        scrollUpPosition: window.pageYOffset
                    });
                }
            }
        } else {
            this.setState({
                slideNavBar: false
            });
        }

        if (window.pageYOffset < this.state.navBar) {
            this.setState({
                showNavBar: false
            });
        } else {
            this.setState({
                showNavBar: true
            });
        }

        this.setState({
            previousScrollPosition: window.pageYOffset
        });
    }

    handleSearch(e) {
        this.setState({searchTerm: e.target.value});
    }

    render() {

        const header = (
            <div ref='navBar' className='relative border-bottom border-light-grey-001 z3'>
                <div className='clearfix container py5 sm-py6'>
                    <div className='col col-9 sm-col-6'>
                        <a href='/'>
                            <img className='block col-9 md-col-6 pl5' src='/images/logo.svg' />
                        </a>
                    </div>
                    <div className='col col-3 sm-col-6'>
                        <div className='right pt4 sm-pt1'>
                            <div className='inline-block'>
                                <div
                                    style={{
                                        transform: 'translateY(3px)',
                                        fontSize: '1.5rem'
                                    }}
                                    className='relative sm-show cursor-pointer icon-search'
                                    onClick={this.toggleSearch} />
                            </div>
                            <div className='inline-block'>
                                <div
                                    onClick={this.toggleMenu}
                                    className={`burger-icon px5 ${this.state.active ? 'js-is-active' : ''}`}>
                                    <span className='line' />
                                    <span className='line' />
                                    <span className='line' />
                                </div>
                            </div>
                        </div>
                        <div className='sm-show pl5 overflow-hidden'>
                            <div
                                style={{marginLeft: this.state.searchActive ? '0%' : '100%'}}
                                className='transition-margin border-bottom border-accent-green'>
                                <form onSubmit={this.submitSearch}>
                                    <input
                                        ref='desktopSearchBox'
                                        className='bg-transparent col-12 border-0 caps py2 h7 caps line-height-1'
                                        placeholder='search...'
                                        onChange={this.handleSearch}
                                        value={this.state.searchTerm}
                                        type='text'/>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );

        const stats = (
            <div className='sm-show'>
                <div className='clearfix container pb6 pt5'>
                    <div className='col-3 col pl5'>
                        <a className='col underline-accent-green' href='/food-and-drink'>
                            <div className='font-weight-500'>
                                {util.try(()=> this.props.settings.stats['food_drink'])}
                            </div>
                            <span className='line-height-1 pt1'>
                                Food & Drink
                            </span>
                        </a>
                    </div>
                    <div className='col-3 col pl5'>
                        <a className='col underline-accent-green' href='/shops-and-stalls'>
                            <div className='font-weight-500'>
                                {util.try(()=> this.props.settings.stats['shops_stalls'])}
                            </div>
                            <span className='line-height-1 pt1'>
                                Shops & Stalls
                            </span>
                        </a>
                    </div>
                    <div className='col-3 col pl5'>
                        <a className='col underline-accent-green' href='/events'>
                            <div className='font-weight-500'>
                                {util.try(()=> this.props.settings.stats.events)}
                            </div>
                            <span className='line-height-1 pt1'>
                                Events
                            </span>
                        </a>
                    </div>
                    <div className='col-3 col pl5'>
                        <a className='col underline-accent-green' href='/visitor-information'>
                            <div className='font-weight-500'>
                                7
                            </div>
                            <div className='line-height-1 pt1 mt1'>
                                Days a week
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        );

        const menu = (
            <div className='relative sm-absolute top-0 sm-top-auto bottom-0 sm-bottom-auto left-0 right-0 bg-white z2'>

                {/* Desktop Nav */}
                <div className='sm-show container'>
                    <div className='relative clearfix pb7 md-p0'>
                        {Object.keys(this.links).map((group, i) => (
                            <Fragment key={i}>
                                {i % 2 === 0 && (<div className="clearfix md-clearfix-0" />)}
                                <div className='col col-6 md-col-3'>
                                    <ul className='list-reset m0 pl5 pt6 md-pb7'>
                                        <div className='grey-003 h7 caps'>{group}</div>
                                        <div className='grey-003'>–</div>
                                        {this.links[group].map((link, j) => (
                                            <li
                                                key={j}
                                                className='py1'>
                                                <a
                                                    className='h4 caslon'
                                                    href={link.value}>
                                                    {link.label}
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </Fragment>
                        ))}
                    </div>
                </div>

                {/* Mobile Nav */}
                <div className='sm-hide'>
                    <div className='fixed top-0 bottom-0 left-0 right-0 bg-white' />
                    <div className='relative clearfix'>
                        <div className='border-bottom border-light-grey-001 px5 py4'>
                            <div className='col-12'>
                                <div className='right'>
                                    <div
                                        style={{fontSize: '1.5rem'}}
                                        className='cursor-pointer icon-search'
                                        onClick={this.toggleSearch} />
                                </div>
                                <div className='overflow-hidden'>
                                    <form onSubmit={this.submitSearch}>
                                        <input
                                            className='col-12 border-0 caps py2 h7 caps line-height-1'
                                            placeholder='search...'
                                            onChange={this.handleSearch}
                                            value={this.state.searchTerm}
                                            type='text'/>
                                    </form>
                                </div>
                            </div>
                        </div>
                        {Object.keys(this.links).map((group, i) => (
                            <ul key={i} className='list-reset pl5 h7 caps pt4'>
                                <div className='grey-003'>{group}</div>
                                <div className='grey-003'>–</div>
                                {this.links[group].map((link, j) => (
                                    <li
                                        key={j}
                                        className='py1'>
                                        <a
                                            className='border-bottom border-accent-green'
                                            href={link.value}>
                                            {link.label}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        ))}
                    </div>
                </div>
            </div>
        );

        return (
            <div className='bg-white'>
                {header}
                <div className='relative'>
                    {this.state.active && menu}
                    {stats}
                </div>
            </div>
        );
    }

}


module.exports = Header;
