/*
 * Journal listing page
 */

import React, {Fragment} from 'react';
import util from '../utilities';
import Header from '../partials/global/header.jsx';
import Footer from '../partials/global/footer.jsx';
import Page from '../partials/layout/page.jsx';
import Image from '../partials/images/image.jsx';
import TraderMap from '../partials/ui/traders-map.jsx';
import RelatedContent from '../partials/layout/related-content.jsx';


class TradersPage extends React.Component {

    constructor() {
        super();

        this.state = {
            dropdown: false
        };
    }

    setDropdownState(key) {
        if (this.state.dropdown !== key) {
            this.setState({dropdown: key});
        } else {
            this.setState({dropdown: false});
        }
    }

    render() {

        if (!util.isArray(this.props.page.traders)) {
            return (<span />);
        }

        // split traders into two columns
        const Grid = (props) => {

            let left = [];
            let right = [];

            props.children.forEach((child, i) => {
                const isEven = n => n % 2 === 0;
                if (isEven(i)) {
                    left.push(child);
                } else {
                    right.push(child);
                }
            });

            return (
                <div>
                    <div className="col col-12 md-col-6">
                        {left}
                    </div>
                    <div className="col col-12 md-col-6">
                        {right}
                    </div>
                </div>
            );
        };

        // serialize trader objects to only keep relevant properties
        const posts = this.props.page.traders.reduce((res, obj, i) => {

            // get group for each trader
            const getType = (obj) => {
                return util.try(() => obj.terms['food-drink-type'][0].name) ||
                       util.try(() => obj.terms['shop-stall-type'][0].name) ||
                       (obj.type === 'food_drink' && 'Food and Drink') ||
                       (obj.type === 'shops_stalls' && 'Shops and Stalls') ||
                       util.try(() => obj['food-drink-type'].name) ||
                       util.try(() => obj['shop-stall-type'].name);
            };

            if (obj.type === 'featured' &&
                util.try(() => obj.related[0])) {

                const _obj = obj.related[0];

                res.push({
                    id: i + 1,
                    name: _obj.title,
                    url: _obj.link,
                    type: getType(_obj)
                });
            } else if (obj.type === 'custom') {

                const _obj = obj.custom[0];

                res.push({
                    id: i + 1,
                    name: _obj.stall_name,
                    url: _obj.stall_link || '#',
                    type: getType(_obj.stall_group[0])
                });
            }

            return res;
        }, []);

        const getColour = (type) => {
            switch (type) {
            case 'Shops':
                return '#42615e';
            case 'Food and Drink':
                return '#d0ccb9';
            case 'The Kitchens':
                return '#99865d';
            case 'Restaurants':
                return '#c7b487';
            case 'Food and Beverage Stalls':
                return '#d0ccb9';
            case 'Market Stalls':
                return '#ebf5ec';
            }
        };

        // group traders by taxonomy
        let unordered = {};
        posts.forEach((post, i) => {
            const key = post.type;
            if (!unordered[key]) {
                unordered[key] = {};
                unordered[key].posts = [];
                unordered[key].color = getColour(key);
            }
            unordered[key].posts.push(post);
        });

        // order grouped posts by length
        let ordered = {};
        Object.keys(unordered)
            .sort((a, b) => unordered[a].length < unordered[b].length)
            .forEach((key) => ordered[key] = unordered[key]);

        // add WC & ATM to grouped list
        ordered['WC & ATM'] = {
            posts: [],
            color: '#000'
        };

        // render a groupd of traders as a dropdown
        const groupedTraders = Object.keys(ordered).map((key, i) => {

            const isDroppedDown = this.state.dropdown === key
                ? 'show'
                : 'sm-show';

            // toggle dropdown if group has posts
            const dropdownMethod = ordered[key].posts.length > 0
                ? this.setDropdownState.bind(this, key)
                : () => null;

            // if group has posts give appropriate icon
            const icon = (ordered[key].posts.length < 1)
                ? ''
                : (this.state.dropdown === key)
                    ? 'icon-chevron-up'
                    : 'icon-chevron-down';


            // render a single trader
            const traders = ordered[key].posts
                .sort((a, b) => {
                    const name = n => n.name.toLowerCase();
                    return name(a) > name(b) ? 1 : name(a) < name(b) ? -1 : 0;
                })
                .map((item, j) => {

                    const index = String(item.id).length > 1
                        ? String(item.id)
                        : String('0' + item.id);

                    const Wrap = (props) => item.url ? (
                        <a
                            className='block underline-accent-green'
                            href={item.url}>
                            {props.children}
                        </a>
                    ) : (
                        <Fragment>
                            {props.children}
                        </Fragment>
                    );

                    const border = ordered[key].posts.length !== j && j !== 0
                        ? 'border-top border-light-grey-001'
                        : '';

                    return (
                        <li
                            key={j}
                            className={`m0 px5 pt2 mb2 ${border}`}>
                            <Wrap>
                                <div className='right grey line-height-2'>
                                    {index}
                                </div>
                                <div
                                    className='mbn1 overflow-hidden caslon truncate pr3'
                                    dangerouslySetInnerHTML={{__html: item.name}} />
                            </Wrap>
                        </li>
                    );
                });

            const border = Object.keys(ordered).length - 1 === i
                ? 'sm-border-bottom border-light-grey-001'
                : 'border-bottom border-light-grey-001';

            return (
                <div key={key}>
                    <div className='col-12'>
                        <div
                            onClick={dropdownMethod}
                            className='relative px5 pb4 pt5 cursor-pointer sm-cursor-default'>
                            <div className='caslon h4 font-weight-500 ml6 sm-m0 sm-pr5 truncate'>
                                {key}
                            </div>
                            <div className={`absolute top-0 bottom-0 left-0 right-0 ${border}`}>

                                <div
                                    className='circle v-centre left-0 sm-left-auto sm-right-0 ml5 mr5'
                                    style={{height: '1rem', width: '1rem', backgroundColor: ordered[key].color}} />

                                <div className='sm-hide v-centre absolute right-0 ml5 mr5'>
                                    <span className={`${icon} h5`} />
                                </div>
                            </div>
                        </div>
                        {util.isArray(traders) && (
                            <ul className={`${isDroppedDown} m0 list-reset border-bottom border-light-grey-001`}>
                                {traders}
                            </ul>
                        )}
                    </div>
                </div>
            );
        });

        return (
            <Page>
                <Header settings={util.try(() => this.props.settings)} />
                <div className='border-bottom border-light-grey-001 mbn1px'>
                    <h1 className='container caslon h2 px5 m0 pt7 pb5 border-bottom border-light-grey-001'>
                        {'Market Map'}
                    </h1>
                    <div className='clearfix container relative'>
                        <div className='sm-sticky top-0 col-right col-12 sm-col-8 md-col-6 mx-auto px3 pt3 sm-py6 sm-pb3 sm-p0 sm-pr5 md-px5'>
                            <Image
                                className='col-12'
                                image={util.try(() => this.props.page.map)} />
                        </div>
                        <div className="col col-12 sm-col-4 md-col-6">
                            <Grid>
                                {groupedTraders}
                            </Grid>
                        </div>
                    </div>
                </div>
                <RelatedContent
                    title='Related Content'
                    content={util.try(() => this.props.page.related)} />
                <Footer settings={this.props.settings} />
            </Page>
        );
    }
}

module.exports = TradersPage;
