/*
 * Home
 */

import React from 'react';
import util from '../utilities';
import Header from '../partials/global/header.jsx';
import Footer from '../partials/global/footer.jsx';
import Page from '../partials/layout/page.jsx';
import Col from '../partials/layout/columns.jsx';
import Image from '../partials/images/image.jsx';
import BackgroundImage from '../partials/images/background.jsx';
import Instagram from '../partials/home/instagram.jsx';
import Newsletter from '../partials/home/newsletter.jsx';
import HomepageCTA from '../partials/home/call-to-action.jsx';
import Listing from '../partials/home/listing.jsx';
import TagCloud from '../partials/layout/tag-cloud.jsx';
import FullWidthCTA from '../partials/home/full-width-cta.jsx';


class Home extends React.Component {

    constructor(props) {
        super(props);
        this.handleScroll = this.handleScroll.bind(this);
        this.state = {
            darkenBg: false,
            navBarBottom: null,
            opacity: 0.3
        };
    }

    componentDidMount() {

        const navBarBottom = this.refs.navBar.getBoundingClientRect().bottom + window.pageYOffset;
        this.setState({
            navBarBottom: navBarBottom
        });

        const handleAnimationFrame = () => {
            if (this.didScroll) {
                this.handleScroll();
                this.didScroll = false;
            }
            window.requestAnimationFrame(handleAnimationFrame);
        };

        window.addEventListener('scroll', () => this.didScroll = true);

        window.requestAnimationFrame(handleAnimationFrame);

        window.addEventListener('resize', () => {
            const navBarBottom = this.refs.navBar.getBoundingClientRect().bottom + window.pageYOffset;
            this.setState({
                navBarBottom: navBarBottom
            });
        });

    }

    handleScroll() {
        if (window.pageYOffset < this.state.navBarBottom) {
            this.setState({
                opacity: 0.3
            });
        } else if (window.pageYOffset >= this.state.navBarBottom && window.pageYOffset < (this.state.navBarBottom + 600)) {

            let opacity = ((0.466 / this.state.navBarBottom) * window.pageYOffset) - 0.1666;
            if (opacity > 0.7) {
                opacity = 0.7;
            }
            this.setState({
                opacity: opacity
            });
        } else {
            this.setState({
                opacity: 0.7
            });
        }
    }

    render() {

        const heroSrc = util.try(() => this.props.page.acf.hero_image.sizes['custom-extra-large']) ||
                        util.try(() => this.props.page.acf.hero_image.url);

        const heroLink = util.try(() => util.joinPath(this.props.page.acf.hero_link[0].post_type, this.props.page.acf.hero_link[0].slug));

        const backgroundSrc = util.try(() => this.props.page.acf.background_images[0].background_image.sizes['custom-extra-large']) ||
                              util.try(() => this.props.page.acf.background_images[0].background_image.url);

        const mobileBackgroundSrc = util.try(() => this.props.page.acf.mobile_alternative_background_image.sizes['custom-extra-large']) ||
                                    util.try(() => this.props.page.acf.mobile_alternative_background_image.url);

        let fullWidthCTAs;
        if (util.try(() => this.props.page.acf.background_images) && this.props.page.acf.background_images.length > 2) {
            fullWidthCTAs = this.props.page.acf.background_images.slice(2, this.props.page.acf.background_images.length).map((item, i) => {
                return (
                    <FullWidthCTA content={item} key={i} />
                );
            });
        }

        return (
            <Page>
                {util.try(() => this.props.page.acf.display_hero) &&
                    <BackgroundImage
                        src={heroSrc}
                        className='md-sticky top-0'>
                        <div className='container clearfix'>
                            <div className='col-10 sm-col-6'>
                                <div className='mb8 sm-mb9 clearfix'>
                                    <div className='md-mb9'>
                                        <HomepageCTA
                                            link={heroLink}
                                            buttonLabel={util.try(() => this.props.page.acf.hero_button_text)}
                                            callToAction={util.try(() => this.props.page.acf.hero_text)} />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </BackgroundImage>
                }

                <div className='z1 relative'>

                    <div ref='navBar'>
                        <Header settings={util.try(() => this.props.settings)} />
                    </div>

                    <div className='relative'>

                        <div className='relative md-absolute left-0 right-0 top-0 bottom-0'>

                            {/* Sticky background image */}

                            <div className='absolute left-0 right-0 top-0 bottom-0 zn1'>

                                <BackgroundImage
                                    src={backgroundSrc}
                                    className={`md-sticky top-0 height-100 md-h100 blend-multiply bg-light-grey ${mobileBackgroundSrc ? 'sm-show' : ''}`} />

                                {mobileBackgroundSrc &&
                                    <BackgroundImage
                                        src={mobileBackgroundSrc}
                                        className='sm-hide top-0 height-100 md-h100 blend-multiply bg-light-grey' />
                                }

                                <div
                                    className='bg-black absolute top-0 left-0 right-0 bottom-0'
                                    style={{opacity: this.state.opacity}} />

                            </div>

                            <div className='sm-mbn200px md-mb0'>
                                <div className='container clearfix'>
                                    <div className='md-sticky mb9 top-0 right-0 left-0 z1 white clearfix'>
                                        <div className='mb9 clearfix'>
                                            <div className='col-10 sm-col-6 sm-mb10 md-mb9'>
                                                <div className='mb5 md-mb9'>
                                                    <HomepageCTA
                                                        link={util.try(() => this.props.page.acf.background_images[0].call_to_action_link)}
                                                        callToAction={util.try(() => this.props.page.acf.background_images[0].call_to_action_text)} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>


                        {/* Push down cols covering background image */}

                        <div className='md-h100' />

                        <div className='clearfix relative z1'>

                            <Listing
                                items={util.try(() => this.props.page.acf.listing)}
                                primaryFeature={util.try(() => this.props.page.acf.primary_feature[0])} />

                            {/* Tag Box */}
                            <div className='clearfix'>
                                <div className='col sm-col-right col-12 md-col-6 bg-pastel-blue relative'>
                                    <TagCloud
                                        isHomepage={true}
                                        bgColor='bg-gold'
                                        callToAction='Explore all articles within Journal'
                                        tags={util.try(() => this.props.tags.posts)} />
                                </div>
                            </div>

                        </div>
                    </div>

                    <FullWidthCTA content={util.try(() => this.props.page.acf.background_images[1])} />

                    {/* Social Modules */}
                    <div className='relative clearfix'>
                        <div className='sm-show absolute top-0 bottom-0 left-0 col col-12 sm-col-6 bg-gold' />
                        <div className='sm-show absolute top-0 bottom-0 right-0 col col-12 sm-col-6 bg-grey' />
                        <Instagram
                            images={util.try(() => this.props.instagram.items)}/>
                        <div className='container'>
                            <div className='col col-12 sm-col-6 relative'>
                                <div className='sm-hide absolute top-0 bottom-0 col-12 bg-gold' />
                                <Newsletter />
                            </div>
                            {(util.try(() => this.props.page.acf.half_width_call_to_action_text) || util.try(() => this.props.page.acf.half_width_call_to_action_link)) &&
                                <div className='col col-12 sm-col-6 relative'>
                                    <div className='sm-hide absolute top-0 bottom-0 col-12 bg-grey' />
                                    <div className='mb5 md-mb9'>
                                        <HomepageCTA
                                            link={util.try(() => this.props.page.acf.half_width_call_to_action_link)}
                                            buttonLabel={util.try(() => this.props.page.acf.half_width_call_to_action_button_text)}
                                            callToAction={util.try(() => this.props.page.acf.half_width_call_to_action_text)} />
                                    </div>
                                </div>
                            }
                        </div>
                    </div>

                    {util.try(() => fullWidthCTAs) &&
                        <div>
                            {fullWidthCTAs}
                        </div>
                    }

                </div>

                <Footer
                    settings={this.props.settings} />
            </Page>
        );
    }

};

module.exports = Home;
