/*
 * Page
 */

import React from 'react';
import util from '../../utilities';


/**
 * Class representing the page layout
 * @extends React.component
 */

class Page extends React.Component {

    render() {

        return (
            <div className='relative col-12'>

                {/* Shadow Grid*/}
                <div className='shadow-grid absolute left-0 right-0 top-0 bottom-0'>
                    <div className='container relative height-100 sm-border-right sm-border-left border-black'>
                        <div className='absolute col-3 border-right border-black height-100' />
                        <div className='absolute col-6 border-right border-black height-100' />
                        <div className='absolute col-9 border-right border-black height-100' />
                    </div>
                </div>

                {/* Header */}
                <div className='relative col-12'>
                </div>

                {/* Page */}
                <article className='col-12'>
                    <div className='relative'>
                        {this.props.children}
                    </div>
                </article>

            </div>
        );
    }

};

module.exports = Page;
