import React from 'react';


const TrackingScripts = (props) => {
    return props.allowsCookies ? (
        <span dangerouslySetInnerHTML={{__html:
            `<script>
                window.fbAsyncInit = function() {
                    FB.init({
                        appId      : '130147030954863',
                        cookie     : true,
                        xfbml      : true,
                        version    : 'v2.7'
                    });
                    FB.AppEvents.logPageView();
                };
                (function(d, s, id){
                    var js, fjs = d.getElementsByTagName(s)[0];
                    if (d.getElementById(id)) {return;}
                    js = d.createElement(s); js.id = id;
                    js.src = "//connect.facebook.net/en_US/sdk.js";
                    fjs.parentNode.insertBefore(js, fjs);
                }(document, 'script', 'facebook-jssdk'));
            </script>
            <script async src="https://www.googletagmanager.com/gtag/js?id=UA-88786437-1"></script>
            <script>
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', 'UA-88786437-1');
            </script>`
        }} />
    ) : null;
};

module.exports = TrackingScripts;
