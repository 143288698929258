import React from 'react';

class NeighbourhoodCardForm extends React.Component {

  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
        message: false,
        firstname: '',
        lastname: '',
        email: '',
        postcode: '',
        company: '',
        tcs: false,
        over_18: false,
        newsletter: false,
        loading: false,
        subscribed: false,
        live_work: ''
    };
  }

  handleChange(type, e) {
      const state = {[type]: e.target.value};
      this.setState(state);
  }

  /**
   * Handle submit
   * @param {object} e
   */
  handleSubmit(e) {
      e.preventDefault();
      let postcodeMatch = false;
      const postcodes = [
        'E16','E15','E11','E17','E18','EC2A','EC2M','EC3A','EC2N'
      ]
      const postcodeTest = this.state.postcode.replace(' ', '');
      for(let i=0; i<postcodes.length; i++) {
        if(postcodeTest.toUpperCase().includes(postcodes[i])) {
            postcodeMatch = true;
        }
      }
      if(!postcodeMatch) {
        this.updateMessage('postcode');
        return false
      }
      if(!this.state.tcs) {
        this.updateMessage('tcs');
        return false
      }
      if(!this.state.over_18) {
        this.updateMessage('over_18');
        return false
      }
      if (!this.state.email.length <= 0) {

          if (!this.state.subscribed) {
              this.setState({
                  loading: true
              }, this.postForm);
          }

      } else {
          this.updateMessage('empty');
      }
  }

  /**
   * Post form
   */
  postForm() {
      const post = fetch('/subscribe', {
          method: 'POST',
          body: new FormData(this.refs.form)
      });

      post.then((res) => res.json())
          .then((json) => this.updateMessage(json.message))
          .catch((err) => this.updateMessage('error'));
  }

  /**
   * Update message
   * @param {object} response
   */
  updateMessage(response) {
      const message = {
          'empty': 'You must provide a valid email address in order to subscribe.',
          'success': 'Thank you for registering! You will be receiving an email shortly with your Neighbourhood Card.',
          'duplicate': 'You are already subscribed to our mailing list with this email address.',
          'error': 'There was a problem registering, please try again later.',
          'tcs': 'You need to accept the T&Cs to proceed',
          'over_18': 'You must be over 18 to apply',
          'postcode': 'Your postcode is not in the neighbourhood catchment area'
      };

      let state = {
          message: message[response],
          loading: false
      };

      if(response === 'success') {
          state.subscribed = true;
          state.email = '';
      }

      this.setState(state);
  }



  render() {

    let icon = '';
    let nudge = '';
    let bgColor = '';
    let rotate = '';
    let disabled = false;

    if (this.state.loading) {
        icon = 'icon-spinner';
        bgColor = 'bg-grey-002';
        rotate = 'rotate';
    } else if (this.state.subscribed) {
        icon = 'icon-tick large-icon white';
        bgColor = 'bg-grey-004';
        disabled = true;
    } else {
        icon = 'icon-chevron-right';
        nudge = 'nudge-right';
        bgColor = 'bg-white';
    }

    const message = this.state.message && (
        <div className='col-12 px5 pt6 center h7 caps'>
            {this.state.message}
        </div>
    );


    return (
      <form
        ref='form'
        className='col-12 md-col-6 mx-auto my6'
        onSubmit={this.handleSubmit}>
        <h2 className='center caslon'>Register</h2>
        <input type="hidden" name="neighbourhoodcard" value={true} />
        <div className='mx-auto col-12 md-col-6'>
            <input
                placeholder='first name'
                name='fname'
                className='border-0 p2 col-12 h7 caps center line-height-1 not-rounded bg-pastel-blue'
                onChange={this.handleChange.bind(null, 'firstname')}
                value={this.state.firstname}
                type='text' />
        </div>
        <div className='mx-auto col-12 md-col-6 pt2'>
            <input
                placeholder='last name'
                name='lname'
                className='border-0 p2 col-12 h7 caps center line-height-1 not-rounded bg-pastel-blue'
                onChange={this.handleChange.bind(null, 'lastname')}
                value={this.state.lastname}
                type='text' />
        </div>
        <div className='mx-auto col-12 md-col-6 pt2'>
            <input
                placeholder='email'
                ref='email'
                name='email'
                className='border-0 p2 col-12 h7 caps center line-height-1 not-rounded bg-pastel-blue'
                onChange={this.handleChange.bind(this, 'email')}
                value={this.state.email}
                type='email' />
        </div>
        <div className='mx-auto col-12 md-col-6 pt2'>
            <input
                placeholder='postcode'
                ref='postcode'
                name='postcode'
                className='border-0 p2 col-12 h7 caps center line-height-1 not-rounded bg-pastel-blue'
                onChange={this.handleChange.bind(this, 'postcode')}
                value={this.state.postcode}
                type='text' />
        </div>  

        <div className='mx-auto col-12 md-col-6 pt2'>
            <div className="h7 caps">Do you live, work or study near Spitalfields Market</div>
            <div className="flex items-center mt3">
                <span>
                    <input
                        ref='live_work'
                        name='live_work'
                        className='border-0 p2 col-12 h7 caps center line-height-1 not-rounded bg-pastel-blue'
                        onChange={this.handleChange.bind(this, 'live_work')}
                        value='live'
                        type='radio' />
                </span>
                <label className="h7 caps ml2">Live</label>
            </div>
            <div className="flex items-center">
                <span>
                    <input
                        ref='live_work'
                        name='live_work'
                        className='border-0 p2 col-12 h7 caps center line-height-1 not-rounded bg-pastel-blue'
                        onChange={this.handleChange.bind(this, 'live_work')}
                        value='work'
                        type='radio' />
                </span>
                <label className="h7 caps ml2">Work</label>
            </div>
            <div className="flex items-center">
                <span>
                    <input
                        ref='live_work'
                        name='live_work'
                        className='border-0 p2 col-12 h7 caps center line-height-1 not-rounded bg-pastel-blue'
                        onChange={this.handleChange.bind(this, 'live_work')}
                        value='study'
                        type='radio' />
                </span>
                <label className="h7 caps ml2">Study</label>
            </div>                
        </div>

        <div className='mx-auto col-12 md-col-6 pt2'>
            <label className="h7 caps line-height-1" for="">Name of Company/place of study (if resident write ‘resident’)  </label>
            <input
                placeholder='Company/place of study'
                ref='company'
                name='company'
                className='border-0 p2 col-12 h7 caps center line-height-1 not-rounded bg-pastel-blue'
                onChange={this.handleChange.bind(this, 'company')}
                value={this.state.company}
                type='text' />
        </div>

        <div className='mx-auto col-12 md-col-6 pt2 mt5'>
            <div className="h7 caps flex">
                <div>
                    <input
                        ref='tcs'
                        name='tcs'
                        className='border-0 p2 col-12 h7 caps center line-height-1 not-rounded bg-pastel-blue'
                        onChange={this.handleChange.bind(this, 'tcs')}
                        value={this.state.tcs}
                        type='checkbox' />
                </div>
                <label className='pl2'>I have read and accepted the Friends of the Market <a href="/terms-and-conditions" target='_blank'>terms and conditions</a>.</label>
            </div>
        </div>

        <div className='mx-auto col-12 md-col-6 pt2'>
            <div className="h7 caps flex">
                <div>
                    <input
                        ref='over_18'
                        name='over_18'
                        className='border-0 p2 col-12 h7 caps center line-height-1 not-rounded bg-pastel-blue'
                        onChange={this.handleChange.bind(this, 'over_18')}
                        value={this.state.over_18}
                        type='checkbox' />
                </div>
                <label className='pl2'>I am over 18</label>
            </div>
        </div>            

        <div className='mx-auto col-12 md-col-6 pt2'>
            <div className="h7 caps flex">
                <div>
                    <input
                        ref='newsletter'
                        name='newsletter'
                        className='border-0 p2 col-12 h7 caps center line-height-1 not-rounded bg-pastel-blue'
                        onChange={this.handleChange.bind(this, 'newsletter')}
                        value={this.state.tcs}
                        type='checkbox' />
                </div>
                <span className='pl2'>I would like to be kept up to date with Old Spitalfields Market news and offers. Please sign me up to your newsletter.</span>
            </div>
        </div>   
           
        <div className='col-6 mx-auto pt2'>
            <button
                type='submit'
                disabled={disabled}
                className='button p0 col-12'>
                <div className='col-12 caps h7 line-height-1 bg-accent-green cursor-pointer p2 black'>
                    subscribe
                </div>
            </button>
        </div>
        {message}
    </form>  
    )
  }
}

module.exports = NeighbourhoodCardForm;
