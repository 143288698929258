import React from 'react';


export default class Menu extends React.Component {
    render() {
        return (
            <div className='bg-pastel-yellow'>
                <div className='clearfix container bg-pastel-white'>
                    <div className='px5 md-px9 py7 md-py9'>
                        <div className='center blue caslon'>
                            <div className='h3 pb4 ag-book'>
                                {this.props.title}
                            </div>
                            <a
                                className='h4'
                                target='_blank'
                                href={this.props.link}>
                                <button className='button m0 p0 caslon border-bottom border-accent-green'>
                                    View Menu
                                </button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
