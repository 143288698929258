/*
 *  Article / Current. News, blog, press etc.
 */

import React from 'react';
import AjaxListing from '../base/infinite-ajax-listing.jsx';
import util from '../../../utilities';

// Grid listing items
import FeatureItem from '../items/feature-business-item.jsx';
import Item from '../items/item.jsx';
import ListingGrid from '../base/listing-grid.jsx';

/**
 * Class representing the Article
 * @extends AjaxListing
 */
class MainListing extends AjaxListing {

    render() {
        const blockSize = 7;
        let items;
        let arItems = this.state.items;

        // console.log(this.props);

        if(Array.isArray(arItems) && arItems.length > 0
        && this.props.query.pages === 1
        && !this.props.query.type
        && !this.props.query.orderby
        && Array.isArray(this.props.featuredItems)) {

            //
            // combine our featured if on listing landing page, with no subject filter
            arItems= this.props.featuredItems.concat(arItems);


        }

        // console.log(arItems);

        if (util.try(() => arItems)) {
            items = arItems.map((item, i) => {
                //
                // Ever 7th son, feature.
                if(i % 7 === 0) {
                    return (
                        <FeatureItem
                            key={i}
                            baseUrl={this.props.baseUrl}
                            item={item}
                        />
                    );
                } else {
                    return (
                        <Item
                            key={i}
                            baseUrl={this.props.baseUrl}
                            item={item}
                        />
                    );
                }
            });
        }

        return (
            <div className="mln1px clearfix">
                <ListingGrid
                    loading={this.props.morePages}
                    blockSize={blockSize}>
                    {items}
                </ListingGrid>
                {this.state.items.length <= 0 && !this.state.loading &&
                    <div className='container'>
                        <h4 className='caslon px5 md-pr9 m0 pt7 pb5'
                            dangerouslySetInnerHTML={{__html: 'There are no such entries in "' + this.props.title + '"'}}>
                        </h4>
                    </div>
                }
            </div>
        );
    }
}

module.exports = MainListing;
