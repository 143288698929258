/*
 *  Featured homepage listings
 */

import React from 'react';
import util from '../../utilities';
import Col from '../layout/columns.jsx';
import Image from '../images/image.jsx';
import ListingItem from './listing-item.jsx';
import PrimaryListingItem from './primary-listing-item.jsx';


/**
 * @prop {array} items
 * @prop {object} primaryFeature
 */

class Listing extends React.Component {

    componentWillMount() {
        this.items = util.try(() => this.props.items) || [];
    }

    render() {

        let items = this.items;

        const blocks = util.try(() => items.map((item, i) => {
            let borderString = '';
            if ((i % 2) === 0) {
                borderString = ' sm-border-right ';
            } else {
                borderString = ' sm-border-left mln1px';
            }
            if (i < 2) {
                borderString = borderString + ' md-show';
            } else {
                borderString = borderString + ' border-top';
            }
            if (util.try(() => item.featured)) {
                borderString = borderString + ' sm-hide';
            }
            if (i === 3) {
                borderString = borderString + ' md-mtn1px';
            }
            return (
                <ListingItem
                    item={item}
                    className={'border-light-grey-002' + borderString} key={i} />
            );
        }));

        return (
            <div>

                {/* Sticky first column */}
                <div className='absolute left-0 right-0 top-0 bottom-0 md-show md-overlap-30vh border-light-grey-002'>
                    <div className='container height-100'>
                        <div
                            className='col col-6 sticky top-0 border-right border-light-grey-002'>
                            <PrimaryListingItem
                                item={util.try(() => this.props.primaryFeature)}
                                className='col-12' />
                        </div>
                    </div>
                </div>

                {/* Second column */}
                <div className='clearfix container'>
                    <div
                        className='col md-col-right col-12 md-col-6 container relative md-overlap-60vh'>

                        <div className='absolute zn1 top-0 right-0 left-0 bottom-0 mt9 sm-show md-hide bg-gold' />

                        <div className='relative col-12 md-hide clearfix bg-white'>
                            <div className='absolute col-6 sm-border-right border-light-grey-002 left-0 top-0 bottom-0 z1 pointer-events-none' />
                            <div className='col sm-col-6'>
                                <ListingItem
                                    item={util.try(() => items[0])}
                                    className='col-12 ' />
                            </div>
                            <div className='col sm-col-6 sm-mtn8'>
                                <ListingItem
                                    item={util.try(() => items[1])}
                                    className='col-12 border-top sm-border-0 border-light-grey-002 ' />
                            </div>
                        </div>

                        <div className='clearfix'>
                            <ListingItem
                                isPrimary={true}
                                item={util.try(() => this.props.primaryFeature)}
                                className='col col-12 sm-hide border-top border-light-grey-002' />
                            <PrimaryListingItem
                                item={util.try(() => this.props.primaryFeature)}
                                className='col col-12 sm-show md-hide border-top border-light-grey-002' />
                        </div>

                        <div className='clearfix'>
                            <Col items={blocks} count={2} responsive={true} shiftUpCol={1} />
                        </div>
                    </div>


                </div>
            </div>
        );
    }
}

module.exports = Listing;
