import React from 'react';
import util from '../../utilities';
import HomepageCTA from '../home/call-to-action.jsx';


class TagCloud extends React.Component {
    render() {
        if (!util.isArray(this.props.tags)) {
            return null;
        }

        let title;
        if (this.props.isHomepage) {
            title = (
                <div className='mb2 col-8 sm-col-12 md-col-11'>
                    <HomepageCTA
                        link='/journal'
                        callToAction={'Explore all articles within Journal'} />
                </div>
            );
        } else {
            title = (
                <div className='caps h7 black font-weight-500 pb4 pt6 md-pt7 lg-pb5 px5 lg-px9'>
                    Tags
                </div>
            );
        }

        const tags = this.props.tags.map((tag, i) => (
            <a
                key={i}
                href={`${this.props.isHomepage ? '/journal?subject=' + tag.id : '/tags/' + tag.slug}`}
                className='inline-block mr1 mb1'>
                <div className='py1 bg-white bg-hover-accent-green'>
                    <div
                        className='px3 py1 caps h7 blue'
                        dangerouslySetInnerHTML={{__html: tag.name}} />
                </div>
            </a>
        ));

        const bgColor = this.props.bgColor || 'bg-pastel-blue';

        return (
            <div className={(this.props.isHomepage ? ' md-pb6 ' : '') + bgColor}>
                <div className={(this.props.isHomepage ? 'md-limit-line-width' : '')}>
                    <div className='container pb6 md-pb8'>
                        {title}
                        <div className='px5 lg-pl9 md-pr0 md-col-11'>
                            {tags}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

module.exports = TagCloud;
