/*
 *  Article / Current. News, blog, press etc.
 */

import React from 'react';
import AjaxListing from '../base/ajax-listing.jsx';
import util from '../../../utilities';

// Grid listing items
import FeatureItem from '../items/feature-item.jsx';
import Item from '../items/item.jsx';

import ListingGrid from '../base/listing-grid.jsx';

/**
 * Class representing the Article
 * @extends AjaxListing
 */
class JournalListing extends AjaxListing {

    render() {

        const blockSize = 7;
        let items;
        let arItems = this.state.items;

        if(this.props.query.page === 1 && !this.props.query.subject) {
            //
            // combine our featured if on listing landing page,
            // with no subject filter
            arItems= this.props.featuredItems.concat(arItems);
        }

        if (util.try(() => arItems)) {
            items = arItems.map((item, i) => {
                //
                // Ever 7th son, feature.
                if(i % blockSize === 0) {
                    return (
                        <FeatureItem
                            key={i}
                            baseUrl='/journal'
                            item={item} />
                    );
                } else {
                    return (
                        <Item
                            isJournal={true}
                            key={i}
                            baseUrl='/journal'
                            item={item} />
                    );
                }
            });
        }

        return (
            <div className='mln1px clearfix'>
                <ListingGrid blockSize={blockSize}>
                    {items}
                </ListingGrid>
                {this.state.items.length <= 0 && !this.state.loading && (
                    <div className='container'>
                        <h4 className='caslon px5 md-px9 m0 pt7 pb5'>
                            There are no journal articles with this tag
                        </h4>
                    </div>
                )}
            </div>
        );
    }
}

module.exports = JournalListing;
