import React from 'react';


const CookieBanner = (props) => {
    return props.allowsCookies === undefined ? (
        <div className='z4 bg-black fixed md-relative bottom-0 left-0 right-0'>
            <div className='clearfix relative container white p5'>
                <div className='col col-12 sm-col-7'>
                    <span className='mr2 mb2'>
                        Like most websites we use cookies to ensure we give you the best experience on our website.
                        By continuing to use this site you are agreeing to our cookie policy.
                    </span>
                    <a
                        className='nowrap border-bottom border-accent-green'
                        href='/privacy-policy'>
                        More information
                    </a>
                </div>
                <div className='col col-12 sm-col-5'>
                    <div className='sm-v-centre right right-0 pt4 sm-p0 sm-pr5'>
                        <div
                            onClick={props.onHandleClick.bind(this, false)}
                            className='inline-block caps sm-mx4 px4 my2'>
                            <span className='cursor-pointer mx4 border-bottom border-accent-green'>
                                reject
                            </span>
                        </div>
                        <div
                            onClick={props.onHandleClick.bind(this, true)}
                            className='cursor-pointer inline-block black caps px4 py2 bg-accent-green'>
                            accept
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ) : null;
};

module.exports = CookieBanner;
