/*
 * Tags listing page
 */

import React from 'react';
import util from '../utilities';
import Header from '../partials/global/header.jsx';
import Footer from '../partials/global/footer.jsx';
import Page from '../partials/layout/page.jsx';
import Image from '../partials/images/image.jsx';
import ListingComponent from '../partials/listing/tags/index.jsx';

class TagsListingPage extends React.Component {

    render() {
        return (
            <Page>
                <Header settings={util.try(() => this.props.settings)} />
                <ListingComponent
                    page={this.props.page}
                    items={util.try(() => this.props.posts.posts)}
                    total={util.try(() => this.props.posts.total)}
                    totalPages={util.try(() => this.props.posts.totalPages)}
                    currentPage={util.try(() => this.props.posts.currentPage)}
                    nextPage={util.try(() => this.props.posts.nextPage)}
                    filters={this.props.filters} />
                <Footer settings={this.props.settings} />
            </Page>
        );
    }
}

module.exports = TagsListingPage;
